import Fuse from 'fuse.js';
import UseContext from 'hooks/useContext';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import removeAccents from 'remove-accents';
import {
  BellIcon,
  Building,
  ChevronDown,
  ChevronTop,
  Close,
  CrossIcon,
  DocumentIcon,
  LinkIcon,
  Pencil,
  ProfilIcon,
  SearchLoop,
  TrashIcon,
} from '../../../assets/icons';
import AddressGeoApi from '../../../components/AddressGeoApi/AddressGeoApi';
import useSlideOver from '../../../components/SlideOver/useSlideOver';
import Badge from '../../../components/badge/badge';
import BadgeSquare from '../../../components/badge/badgeSquare';
import Banner from '../../../components/banner/banner';
import Button from '../../../components/button';
import CopyToClipboard from '../../../components/copyToClipboard';
import Divider from '../../../components/divider/divider';
import IdentityFileItem from '../../../components/identityFileItem/IdentityFileItem';
import useModal from '../../../components/modal/useModal';
import Pagination from '../../../components/pagination/pagination';
import Salary from '../../../components/salary/Salary';
import Schedule from '../../../components/schedule/schedule';
import StyledInput from '../../../components/styledInput/StyledInput';
import TempWorkerMission from '../../../components/tempWorkerMission/tempWorkerMission';
import {
  dateToEpoch,
  dateToIsoString,
  dateWordShort,
} from '../../../helper/helper';
import usefetchdata from '../../../hooks/usefetchdata';
import ApiDatabase from '../../../server';
import MissionDetailTempWorker from './missionDetailTempWorker';

const AdminMissionDetails = ({
  missionId,
  searchMissionResult,
  setSearchMissionResult,
}) => {
  const { toggleSlideOver } = useSlideOver();
  const {
    userUnAssigned,
    setUserUnAssigned,
    modalDataReturn,
    setModalDataReturn,
    objID,
    setObjID,
    setTarget,
    assignationMissionTempWorker,
    setAssignationMissionTempWorker,
    modifyMissionDetailData,
    setModifyMissionDetailData,
    setListChangesMissionDetail,
    setIsChangesNeedsNewContractMissionDetail,
    setAdminLink,
    setAdminType,
    setAdminTempWorkerId,
    searchTempWorkerAssignationResult,
    setSearchTempWorkerAssignationResult,
    searchTempWorkerAssignationRefusedResult,
    setSearchTempWorkerAssignationRefusedResult,
    infoMission,
    setInfoMission,
    searchTempWorkerInviteResult,
    setSearchTempWorkerInviteResult,
    searchTempWorkerResult,
    setSearchTempWorkerResult,
  } = UseContext();
  const [missionDateStart, setMissionDateStart] = useState('');
  const [originalMissionDateStart, setOriginalMissionDateStart] = useState('');
  const [missionDateEnd, setMissionDateEnd] = useState('');
  const [originalMissionDateEnd, setOriginalMissionDateEnd] = useState('');
  const [
    isModificationDateMissionAffectBesttContract,
    setIsModificationDateMissionAffectBesttContract,
  ] = useState(false);
  const [searchTempWorker, setSearchTempWorker] = useState('');
  const [searchTempWorkerResultWaiting, setSearchTempWorkerResultWaiting] =
    useState(false);
  const [searchTempWorkerResultLoadData, setSearchTempWorkerResultLoadData] =
    useState(false);
  const [isTempWorkerResultCompleteOpen, setIsTempWorkerResultCompleteOpen] =
    useState(true);
  const [isTempWorkerResultPartialOpen, setIsTempWorkerResultPartialOpen] =
    useState(true);
  const [
    isTempWorkerResultNotUpdatedOpen,
    setIsTempWorkerResultNotUpdatedOpen,
  ] = useState(true);
  const [
    isTempWorkerResultIncompleteOpen,
    setIsTempWorkerResultIncompleteOpen,
  ] = useState(true);
  const [
    isAssignationMissionAffectAssignedMission,
    setIsAssignationMissionAffectAssignedMission,
  ] = useState(false);
  const [selectedTempWorkerAssignation, setSelectedTempWorkerAssignation] =
    useState(false);
  const [selectedTempWorkerRefused, setSelectedTempWorkerRefused] =
    useState(false);
  const [
    errorAssignationMissionIdentityFiles,
    setErrorAssignationMissionIdentityFiles,
  ] = useState(false);
  const [loadingSignatureMission, setLoadingSignatureMission] = useState(false);
  const [loadingContractGeneration, setLoadingContractGeneration] =
    useState(false);
  const [typeSearchTempWorkerAssignation, setTypeSearchTempWorkerAssignation] =
    useState(1);
  const [countTempWorkerPostulate, setCountTempWorkerPostulate] = useState(0);
  const [countTempWorkerRefused, setCountTempWorkerRefused] = useState(0);
  const [countUsersInvite, setCountUsersInvite] = useState(0);
  const [isInfoMissionWaiting, setIsInfoMissionWaiting] = useState(true);
  const [loadingAssignationMission, setLoadingAssignationMission] =
    useState(false);
  const [loadingModificationDateMission, setLoadingModificationDateMission] =
    useState(false);
  const [
    isModificationDateMissionDifferentFromOriginal,
    setIsModificationDateMissionDifferentFromOriginal,
  ] = useState(false);

  const [
    searchTempWorkerAssignationToDisplay,
    setSearchTempWorkerAssignationToDisplay,
  ] = useState(false);
  const [
    searchTempWorkerAssignationResultWaiting,
    setSearchTempWorkerAssignationResultWaiting,
  ] = useState(false);
  const [searchTempWorkerAssignation, setSearchTempWorkerAssignation] =
    useState('');
  const [
    searchTempWorkerAssignationResultLoadData,
    setSearchTempWorkerAssignationResultLoadData,
  ] = useState(false);

  const [
    searchTempWorkerAssignationRefusedToDisplay,
    setSearchTempWorkerAssignationRefusedToDisplay,
  ] = useState(false);
  const [
    searchTempWorkerAssignationRefusedResultWaiting,
    setSearchTempWorkerAssignationRefusedResultWaiting,
  ] = useState(false);
  const [
    searchTempWorkerAssignationRefused,
    setSearchTempWorkerAssignationRefused,
  ] = useState('');
  const [
    searchTempWorkerAssignationRefusedResultLoadData,
    setSearchTempWorkerAssignationRefusedResultLoadData,
  ] = useState(false);

  const [
    searchTempWorkerInviteResultWaiting,
    setSearchTempWorkerInviteResultWaiting,
  ] = useState(false);

  const [missionPage, setMissionPage] = useState(1);
  const [currentPageTempWorker, setCurrentPageTempWorker] = useState(1);
  const [limitElementTempWorker, setLimitElementTempWorker] = useState(20);
  const [totalPagesTempWorker, setTotalPagesTempWorker] = useState(0);
  const [countPerPageTempWorker, setCountPerPageTempWorker] = useState(0);
  const [countTotalTempWorker, setCountTotalTempWorker] = useState(0);

  const [
    currentPageTempWorkerAssignation,
    setCurrentPageTempWorkerAssignation,
  ] = useState(1);
  const [
    limitElementTempWorkerAssignation,
    setLimitElementTempWorkerAssignation,
  ] = useState(20);
  const [totalPagesTempWorkerAssignation, setTotalPagesTempWorkerAssignation] =
    useState(0);
  const [countTotalTempWorkerAssignation, setCountTotalTempWorkerAssignation] =
    useState(0);

  const [
    currentPageTempWorkerAssignationRefused,
    setCurrentPageTempWorkerAssignationRefused,
  ] = useState(1);
  const [
    limitElementTempWorkerAssignationRefused,
    setLimitElementTempWorkerAssignationRefused,
  ] = useState(20);
  const [
    totalPagesTempWorkerAssignationRefused,
    setTotalPagesTempWorkerAssignationRefused,
  ] = useState(0);
  const [
    countTotalTempWorkerAssignationRefused,
    setCountTotalTempWorkerAssignationRefused,
  ] = useState(0);

  const [modifyMissionDetail, setModifyMissionDetail] = useState(false);
  const [qualifications, setQualifications] = useState([]);

  const [addressMissionDetail, setAddressMissionDetail] = useState({});

  const [licences, setLicences] = useState([]);
  const [tools, setTools] = useState([]);
  const [lang, setLang] = useState([]);

  const [isIdentityFileOpen, setIsIdentityFileOpen] = useState({
    identity: false,
    visa: false,
    card_vitality: false,
  });

  const [isChangesModifyMissionDetail, setIsChangesModifyMissionDetail] =
    useState(false);
  const [
    listAllChangesModifyMissionDetail,
    setListAllChangesModifyMissionDetail,
  ] = useState([]);
  const [
    isChangesNeedsNewContractModifyMissionDetail,
    setIsChangesNeedsNewContractModifyMissionDetail,
  ] = useState(false);

  const [dropdownNotificationOpen, setDropdownNotificationOpen] =
    useState(false);
  const dropDownNotificationRef = useRef();

  const { toggle } = useModal();
  const { userData } = usefetchdata();
  const [role, setRole] = useState(500);

  const token = localStorage.getItem('xsrfToken');
  useEffect(() => {
    editMission({ skipCache: false });
  }, [missionId]);

  const editMission = ({ skipCache }) => {
    setIsInfoMissionWaiting(true);

    setMissionDateStart('');
    setOriginalMissionDateStart('');
    setMissionDateEnd('');
    setOriginalMissionDateEnd('');
    setIsModificationDateMissionAffectBesttContract(false);
    setSearchTempWorker('');
    setSearchTempWorkerResultWaiting(false);
    setSearchTempWorkerResultLoadData(false);
    setSearchTempWorkerResult(false);
    setIsAssignationMissionAffectAssignedMission(false);
    setSelectedTempWorkerAssignation(false);
    setSelectedTempWorkerRefused(false);
    setErrorAssignationMissionIdentityFiles(false);
    setLoadingSignatureMission(false);
    setLoadingContractGeneration(false);
    setTypeSearchTempWorkerAssignation(1);
    setCountTempWorkerPostulate(0);
    setCountTempWorkerRefused(0);
    setCountUsersInvite(0);
    setInfoMission(false);
    setLoadingAssignationMission(false);
    setLoadingModificationDateMission(false);
    setIsModificationDateMissionDifferentFromOriginal(false);
    setSearchTempWorkerAssignationResult(false);
    setSearchTempWorkerAssignationToDisplay(false);
    setSearchTempWorkerInviteResult([]);
    setSearchTempWorkerAssignationResultWaiting(false);
    setSearchTempWorkerInviteResultWaiting(false);
    setSearchTempWorkerAssignation('');
    setSearchTempWorkerAssignationResultLoadData(false);
    setSearchTempWorkerAssignationRefusedResult(false);
    setSearchTempWorkerAssignationRefusedToDisplay(false);
    setSearchTempWorkerAssignationRefusedResultWaiting(false);
    setSearchTempWorkerAssignationRefused('');
    setSearchTempWorkerAssignationRefusedResultLoadData(false);

    setMissionPage(1);
    setCurrentPageTempWorker(1);
    setLimitElementTempWorker(20);
    setTotalPagesTempWorker(0);
    setCountPerPageTempWorker(0);
    setCountTotalTempWorker(0);

    setCurrentPageTempWorkerAssignation(1);
    setLimitElementTempWorkerAssignation(20);
    setTotalPagesTempWorkerAssignation(0);
    setCountTotalTempWorkerAssignation(0);

    setCurrentPageTempWorkerAssignationRefused(1);
    setLimitElementTempWorkerAssignationRefused(20);
    setTotalPagesTempWorkerAssignationRefused(0);
    setCountTotalTempWorkerAssignationRefused(0);

    setModifyMissionDetail(false);

    setAddressMissionDetail({});
    setModifyMissionDetailData({});

    setListChangesMissionDetail([]);
    setIsChangesNeedsNewContractMissionDetail(false);

    setIsIdentityFileOpen({
      identity: false,
      visa: false,
      card_vitality: false,
    });

    setIsChangesModifyMissionDetail(false);
    setListAllChangesModifyMissionDetail(false);
    setIsChangesNeedsNewContractModifyMissionDetail(false);

    manageTempWorkerSearch({ skipCache });
    manageTempWorkerAssignationSearch({ skipCache });
    manageTempWorkerInviteSearch({ skipCache });

    ApiDatabase.getMissionDetailsById(
      { token: token, idMission: missionId },
      (data) => {
        setInfoMission(data);

        setTypeSearchTempWorkerAssignation(1);

        if (data.user_assigned) {
          setMissionPage(2);
        } else {
          setMissionPage(1);
        }

        setIsInfoMissionWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.error(err);
          setInfoMission(false);
          setIsInfoMissionWaiting(false);
        }
      },
      { skipCache }
    );
  };

  const shouldShowTrashButton = (mission) => {
    if (mission.status === 'cancel' || mission.status === 'delete')
      return false;

    const now = new Date();
    const startDate = new Date(mission.duration.datestart);
    const endDate = new Date(mission.duration.dateend);
    const hasAssignedUser = mission.user_assigned;

    let missionStatus;
    if (now < startDate) {
      missionStatus = 'soon';
    } else if (now > endDate) {
      missionStatus = 'past';
    } else {
      missionStatus = 'in_progress';
    }

    if (missionStatus === 'soon' && hasAssignedUser) {
      return 'cancel';
    }

    if (
      (missionStatus === 'in_progress' ||
        missionStatus === 'soon' ||
        missionStatus === 'past') &&
      !hasAssignedUser
    ) {
      return 'delete';
    }

    return false;
  };

  useEffect(() => {
    if (infoMission) {
      if (infoMission.duration?.datestart) {
        setMissionDateStart(dateToEpoch(infoMission.duration.datestart));
        setOriginalMissionDateStart(
          dateToEpoch(infoMission.duration.datestart)
        );
      }
      if (infoMission.duration?.dateend) {
        setMissionDateEnd(dateToEpoch(infoMission.duration.dateend));
        setOriginalMissionDateEnd(dateToEpoch(infoMission.duration.dateend));
      }

      setCountTempWorkerPostulate(
        infoMission.users?.filter((user) => user.refused === false).length || 0
      );
      setCountTempWorkerRefused(
        infoMission.users?.filter((user) => user.refused === true).length || 0
      );

      if (infoMission.users_invite?.length > 0) {
        setCountUsersInvite(infoMission.users_invite.length);
      } else {
        setCountUsersInvite(0);
      }

      if (
        infoMission.user_assigned &&
        infoMission.contract_company[0]?.id_bestt
      ) {
        setIsAssignationMissionAffectAssignedMission(true);
      } else {
        setIsAssignationMissionAffectAssignedMission(false);
      }
    }
  }, [infoMission]);

  useEffect(() => {
    if (infoMission) {
      if (
        (dateToEpoch(missionDateStart).getTime() !==
          dateToEpoch(originalMissionDateStart).getTime() ||
          dateToEpoch(missionDateEnd).getTime() !==
            dateToEpoch(originalMissionDateEnd).getTime()) &&
        infoMission.contract_company[0]?.id_bestt
      ) {
        setIsModificationDateMissionAffectBesttContract(true);
      } else {
        setIsModificationDateMissionAffectBesttContract(false);
      }

      if (
        dateToEpoch(missionDateStart).getTime() !==
          dateToEpoch(originalMissionDateStart).getTime() ||
        dateToEpoch(missionDateEnd).getTime() !==
          dateToEpoch(originalMissionDateEnd).getTime()
      ) {
        setIsModificationDateMissionDifferentFromOriginal(true);
      } else {
        setIsModificationDateMissionDifferentFromOriginal(false);
      }
    }
  }, [missionDateStart, missionDateEnd]);

  const manageTempWorkerSearch = ({ skipCache }) => {
    setSearchTempWorkerResultWaiting(true);
    setSearchTempWorkerResultLoadData(true);

    ApiDatabase.searchTempWorker(
      {
        token,
        missionId: missionId,
        search: searchTempWorker,
        page: currentPageTempWorker,
        limit: limitElementTempWorker,
      },
      (data) => {
        setSearchTempWorkerResult(data.users);

        setCountTotalTempWorker(data.count);
        setCountPerPageTempWorker(data.countPerPage);
        setTotalPagesTempWorker(data.countPages);

        setSearchTempWorkerResultWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          setSearchTempWorkerResult(false);
          console.error(err);
        }
      },
      { skipCache }
    );
  };

  useEffect(() => {
    manageTempWorkerSearch({ skipCache: false });
  }, [currentPageTempWorker]);

  let timerManageTempWorkerSearch = '';
  useEffect(() => {
    timerManageTempWorkerSearch = setTimeout(async () => {
      setCurrentPageTempWorker(1);
      manageTempWorkerSearch({ skipCache: false });
    }, 400);
    return () => clearTimeout(timerManageTempWorkerSearch);
  }, [searchTempWorker]);

  useEffect(() => {
    if (
      (searchTempWorkerResult.length > 0 && selectedTempWorkerAssignation) ||
      (searchTempWorkerAssignationResult.length > 0 &&
        selectedTempWorkerAssignation)
    ) {
      const user =
        (searchTempWorkerResult
          ? searchTempWorkerResult.find(
              (tempWorker) => tempWorker._id === selectedTempWorkerAssignation
            )
          : null) ||
        (searchTempWorkerAssignationResult
          ? searchTempWorkerAssignationResult.find(
              (tempWorker) => tempWorker._id === selectedTempWorkerAssignation
            )
          : null);
      if (user) {
        if (user.tempworker) {
          if (user.tempworker.identity && user.tempworker.card_vitality) {
            //Not added
            if (
              user.tempworker.identity.validated === 0 ||
              user.tempworker.identity.validated === '0' ||
              user.tempworker.card_vitality.validated === 0 ||
              user.tempworker.card_vitality.validated === '0'
            ) {
              setErrorAssignationMissionIdentityFiles(
                "Les documents d'identité n'ont pas étés ajoutés, un mail va être envoyé à l'intérimaire pour qu'il les ajoutent"
              );
            }
            //Added but not verified
            else if (
              user.tempworker.identity.validated === 1 ||
              user.tempworker.identity.validated === '1' ||
              user.tempworker.card_vitality.validated === 1 ||
              user.tempworker.card_vitality.validated === '1'
            ) {
              setErrorAssignationMissionIdentityFiles(
                "Les documents d'identité doivent être vérifiés par un administrateur"
              );
            }
            //Verified
            else if (
              (user.tempworker.identity.validated === 2 ||
                user.tempworker.identity.validated === '2') &&
              (user.tempworker.card_vitality.validated === 2 ||
                user.tempworker.card_vitality.validated === '2')
            ) {
              //Identity and card vitality data is missing
              if (
                !user.tempworker.identity.number_identity ||
                !user.tempworker.card_vitality.social_security
              ) {
                setErrorAssignationMissionIdentityFiles(
                  "Les documents d'identité sont validés mais les données sont manquantes, contactez un développeur"
                );
              } else {
                setErrorAssignationMissionIdentityFiles(false);
              }
            }
            //Refused
            else if (
              user.tempworker.identity.validated === 3 ||
              user.tempworker.identity.validated === '3' ||
              user.tempworker.card_vitality.validated === 3 ||
              user.tempworker.card_vitality.validated === '3'
            ) {
              setErrorAssignationMissionIdentityFiles(
                "Les documents d'identité ont été refusés, un mail de rappel sera envoyé"
              );
            }
          }
        }
      }
    }
  }, [selectedTempWorkerAssignation]);

  useEffect(() => {
    if (userData?.role) {
      setRole(userData.role);
    }
  }, [userData]);

  const missionDateSubmit = (id_mission) => {
    //toggle('ad_mission_date_confirmation')
    setLoadingModificationDateMission(true);

    const dateStart =
      dateToEpoch(missionDateStart).getFullYear() +
      '/' +
      ('0' + (dateToEpoch(missionDateStart).getMonth() + 1))
        .toString()
        .slice(-2) +
      '/' +
      ('0' + dateToEpoch(missionDateStart).getDate()).toString().slice(-2);
    const dateEnd =
      dateToEpoch(missionDateEnd).getFullYear() +
      '/' +
      ('0' + (dateToEpoch(missionDateEnd).getMonth() + 1))
        .toString()
        .slice(-2) +
      '/' +
      ('0' + dateToEpoch(missionDateEnd).getDate()).toString().slice(-2);

    ApiDatabase.modifyMissionPeriod(
      {
        idMission: id_mission,
        dateStart: dateStart,
        dateEnd: dateEnd,
        token,
      },
      async (data) => {
        setLoadingModificationDateMission(false);
        toast.success('La période de la mission a bien été modifiée');
        for (let i = 0; i < searchMissionResult.length; i++) {
          if (searchMissionResult[i].id_mission_real === id_mission) {
            searchMissionResult[i].duration = {
              datestart: dateToEpoch(missionDateStart).toISOString(),
              dateend: dateToEpoch(missionDateEnd).toISOString(),
            };
            break;
          }
        }
        setSearchMissionResult(searchMissionResult);
        await editMission({ skipCache: true });
      },
      (err) => {
        setLoadingModificationDateMission(false);
        toast.error(
          'Une erreur est survenue lors de la modification de la période de la mission : ' +
            err
        );
      }
    );
  };

  const assignationMissionSubmit = (idTempWorker) => {
    setAssignationMissionTempWorker({
      idTempWorker,
      idMission: infoMission._id,
    });
    toggle('ad_mission_assignation_confirmation');
  };

  const sendSignatureMissionSubmit = (missionId) => {
    //toggle('ad_mission_sign_confirmation')
    setLoadingSignatureMission(true);
    ApiDatabase.requestSignContract(
      { idMission: missionId },
      async (data) => {
        setLoadingSignatureMission(false);
        toast.success('La signature a bien été effectuée');
        await editMission({ skipCache: true });
      },
      (err) => {
        setLoadingSignatureMission(false);
        toast.error(
          "Une erreur est survenue lors de l'envoi du mail de signature : " +
            err.response.data.error
        );
      }
    );
  };

  const generationContractSubmit = (missionId) => {
    setLoadingContractGeneration(true);
    ApiDatabase.requestGenerationContract(
      { token: token, idMission: missionId },
      async (data) => {
        setLoadingContractGeneration(false);
        toast.success('La génération du contrat a bien été effectuée');
        await editMission({ skipCache: true });
      },
      (err) => {
        setLoadingContractGeneration(false);
        toast.error(
          'Une erreur est survenue lors de la génération du contrat : ' +
            err.response.data.error
        );
      }
    );
  };

  const manageTempWorkerAssignationSearch = ({ skipCache }) => {
    setSearchTempWorkerAssignationResultWaiting(true);
    setSearchTempWorkerAssignationResultLoadData(true);
    setSearchTempWorkerAssignationRefusedResultWaiting(true);
    setSearchTempWorkerAssignationRefusedResultLoadData(true);

    ApiDatabase.getTempWorkerPostulateMission(
      { token: token, idMission: missionId },
      (data) => {
        let refusedUsers = {
          complete: [],
          partial: [],
          not_updated: [],
          incomplete: [],
        };

        let otherUsers = {
          complete: [],
          partial: [],
          not_updated: [],
          incomplete: [],
        };

        const categories = ['complete', 'partial', 'not_updated', 'incomplete'];

        categories.forEach((category) => {
          if (data.users[category]) {
            refusedUsers[category] = data.users[category].filter(
              (user) => user.refused === true
            );
            otherUsers[category] = data.users[category].filter(
              (user) => user.refused === false
            );
          } else {
            refusedUsers[category] = [];
            otherUsers[category] = [];
          }
        });

        setSearchTempWorkerAssignationRefusedResult(refusedUsers);
        setSearchTempWorkerAssignationResult(otherUsers);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          setSearchTempWorkerAssignationRefusedResultWaiting(false);
          setSearchTempWorkerAssignationRefusedResult(false);
          setSearchTempWorkerAssignationResultWaiting(false);
          setSearchTempWorkerAssignationResult(false);
          console.error(err);
        }
      },
      { skipCache }
    );
  };

  useEffect(() => {
    if (searchTempWorkerAssignationResult) {
      setSearchTempWorkerAssignationResultWaiting(true);

      // Annotate each item with its category
      const searchTempWorkerAssignationToDisplay = Object.entries(
        searchTempWorkerAssignationResult
      ).flatMap(([category, items]) =>
        items.map((item) => ({ ...item, category }))
      );

      let sortedResults = searchTempWorkerAssignationToDisplay;

      if (removeAccents(searchTempWorkerAssignation.toLowerCase()) !== '') {
        const searchTerms = searchTempWorkerAssignation
          .split(' ')
          .map((term) => removeAccents(term.toLowerCase()));

        const options = {
          keys: ['firstname', 'lastname', 'email', 'phone', 'id_bestt', '_id'],
          includeScore: true,
          threshold: 0.3,
          distance: 100,
          shouldSort: true,
          getFn: (obj, path) => {
            const value = Fuse.config.getFn(obj, path);
            if (value == null) {
              return '';
            }
            return removeAccents(value.toString().toLowerCase());
          },
        };

        const fuse = new Fuse(searchTempWorkerAssignationToDisplay, options);
        const combinedResults = {};

        searchTerms.forEach((term) => {
          const result = fuse.search(term);
          result.forEach(({ item, score }) => {
            const indexSearch = `${item._id}${item.search}`;
            if (combinedResults[indexSearch]) {
              combinedResults[indexSearch].score -= score;
            } else {
              combinedResults[indexSearch] = { item, score };
            }
          });
        });

        sortedResults = Object.values(combinedResults)
          .sort((a, b) => a.score - b.score)
          .map((result) => result.item);
      }

      setCountTotalTempWorkerAssignation(sortedResults.length);
      setTotalPagesTempWorkerAssignation(
        Math.ceil(sortedResults.length / limitElementTempWorkerAssignation)
      );
      const start =
        (currentPageTempWorkerAssignation - 1) *
        limitElementTempWorkerAssignation;
      const end = start + limitElementTempWorkerAssignation;

      const paginatedResults = sortedResults.slice(start, end);

      // Group the paginated results back into their respective categories
      const paginatedCategorizedResults = {
        complete: [],
        partial: [],
        not_updated: [],
        incomplete: [],
      };

      paginatedResults.forEach((item) => {
        const category = item.category;
        if (paginatedCategorizedResults[category]) {
          paginatedCategorizedResults[category].push(item);
        }
      });

      setSearchTempWorkerAssignationToDisplay(paginatedCategorizedResults);
      setSearchTempWorkerAssignationResultWaiting(false);
    }
  }, [
    searchTempWorkerAssignationResult,
    searchTempWorkerAssignation,
    currentPageTempWorkerAssignation,
  ]);

  useEffect(() => {
    if (searchTempWorkerAssignationRefusedResult) {
      setSearchTempWorkerAssignationRefusedResultWaiting(true);

      // Annotate each item with its category
      const searchTempWorkerAssignationRefusedToDisplay = Object.entries(
        searchTempWorkerAssignationRefusedResult
      ).flatMap(([category, items]) =>
        items.map((item) => ({ ...item, category }))
      );

      let sortedResults = searchTempWorkerAssignationRefusedToDisplay;

      if (removeAccents(searchTempWorkerAssignation.toLowerCase()) !== '') {
        const searchTerms = searchTempWorkerAssignationRefused
          .split(' ')
          .map((term) => removeAccents(term.toLowerCase()));

        const options = {
          keys: ['firstname', 'lastname', 'email', 'phone', 'id_bestt', '_id'],
          includeScore: true,
          threshold: 0.3,
          distance: 100,
          shouldSort: true,
          getFn: (obj, path) => {
            const value = Fuse.config.getFn(obj, path);
            if (value == null) {
              return '';
            }
            return removeAccents(value.toString().toLowerCase());
          },
        };

        const fuse = new Fuse(
          searchTempWorkerAssignationRefusedToDisplay,
          options
        );
        const combinedResults = {};

        searchTerms.forEach((term) => {
          const result = fuse.search(term);
          result.forEach(({ item, score }) => {
            const indexSearch = `${item._id}${item.search}`;
            if (combinedResults[indexSearch]) {
              combinedResults[indexSearch].score -= score;
            } else {
              combinedResults[indexSearch] = { item, score };
            }
          });
        });

        sortedResults = Object.values(combinedResults)
          .sort((a, b) => a.score - b.score)
          .map((result) => result.item);
      }

      setCountTotalTempWorkerAssignationRefused(sortedResults.length);
      setTotalPagesTempWorkerAssignationRefused(
        Math.ceil(
          sortedResults.length / limitElementTempWorkerAssignationRefused
        )
      );
      const start =
        (currentPageTempWorkerAssignationRefused - 1) *
        limitElementTempWorkerAssignationRefused;
      const end = start + limitElementTempWorkerAssignationRefused;

      const paginatedResults = sortedResults.slice(start, end);

      // Group the paginated results back into their respective categories
      const paginatedCategorizedResults = {
        complete: [],
        partial: [],
        not_updated: [],
        incomplete: [],
      };

      paginatedResults.forEach((item) => {
        const category = item.category;
        if (paginatedCategorizedResults[category]) {
          paginatedCategorizedResults[category].push(item);
        }
      });

      setSearchTempWorkerAssignationRefusedToDisplay(
        paginatedCategorizedResults
      );
      setSearchTempWorkerAssignationRefusedResultWaiting(false);
    }
  }, [
    searchTempWorkerAssignationRefusedResult,
    searchTempWorkerAssignationRefused,
    currentPageTempWorkerAssignationRefused,
  ]);

  const manageTempWorkerInviteSearch = ({ skipCache }) => {
    setSearchTempWorkerInviteResultWaiting(true);
    ApiDatabase.getTempWorkerInviteMission(
      { token: token, idMission: missionId },
      (data) => {
        setSearchTempWorkerInviteResult(data.users_invite ?? []);
        setSearchTempWorkerInviteResultWaiting(false);
      },
      (err) => {
        if (err.name !== 'CanceledError') {
          console.log(err);
          setSearchTempWorkerInviteResultWaiting(false);
          setSearchTempWorkerInviteResult(false);
        }
      },
      { skipCache }
    );
  };

  const unassignMission = (idMission, userAssigned) => {
    if (idMission && userAssigned) {
      setUserUnAssigned({ idMission, userAssigned });
      toggle('d_unassign_mission');
    }
  };

  const handleDeleteMission = () => {
    setObjID(infoMission._id);
    toggle('mission_delete');
  };

  const handleCancelMission = () => {
    setObjID(infoMission._id);
    toggle('mission_cancel');
  };

  useEffect(() => {
    if (modalDataReturn === 'mission canceled') {
      setSearchMissionResult(
        searchMissionResult.map((mission) =>
          mission.id_mission_real === infoMission._id
            ? { ...mission, status: 'cancel' }
            : mission
        )
      );
      editMission({ skipCache: true });
      setModalDataReturn({});
    }
    if (modalDataReturn === 'mission deleted') {
      setSearchMissionResult(
        searchMissionResult.filter(
          (mission) => mission.id_mission_real !== infoMission._id
        )
      );
      setInfoMission(false);
      setModalDataReturn({});
    }
    if (modalDataReturn === 'mission assigned') {
      if (
        assignationMissionTempWorker.idTempWorker &&
        assignationMissionTempWorker.idMission
      ) {
        ApiDatabase.getUserById(
          { token, id_user: assignationMissionTempWorker.idTempWorker },
          (data) => {
            setSearchMissionResult((prevData) => {
              return prevData.map((mission) => {
                if (
                  mission.id_mission_real ===
                  assignationMissionTempWorker.idMission
                ) {
                  return {
                    ...mission,
                    user: {
                      user_id: data._id,
                      firstname: data.firstname,
                      lastname: data.lastname,
                      id_bestt: data.id_bestt,
                      email: data.email,
                      phone: data.phone,
                    },
                  };
                } else {
                  return mission;
                }
              });
            });
            setModalDataReturn({});
            editMission({ skipCache: true });
          },
          (err) => {
            if (err.name !== 'CanceledError') {
              console.log(err);
            }
          }
        );
      }
    }
    if (modalDataReturn === 'mission unassigned') {
      setSearchMissionResult((prevData) => {
        return prevData.map((mission) => {
          if (mission.id_mission_real === missionId) {
            return {
              ...mission,
              user: null,
            };
          } else {
            return mission;
          }
        });
      });
      setModalDataReturn({});
      editMission({ skipCache: true });
    }
    if (modalDataReturn === 'mission updated') {
      setSearchMissionResult((prevData) => {
        return prevData.map((mission) => {
          if (mission.id_mission_real === missionId) {
            return {
              ...mission,
              title: modifyMissionDetailData.qualification,
              duration: {
                datestart: modifyMissionDetailData.period.start,
                dateend: modifyMissionDetailData.period.end,
              },
              reference: modifyMissionDetailData.reference,
              contract_tempworker: [
                {
                  ...mission.contract_tempworker[0],
                  id_bestt:
                    objID || mission.contract_tempworker[0]?.id_bestt || '',
                },
              ],
              contract_company: [
                {
                  ...mission.contract_company[0],
                  id_bestt:
                    objID || mission.contract_company[0]?.id_bestt || '',
                },
              ],
            };
          } else {
            return mission;
          }
        });
      });
      setModalDataReturn({});
      setListChangesMissionDetail([]);
      setIsChangesNeedsNewContractMissionDetail(false);
      setModifyMissionDetail(false);
      setObjID('');
      editMission({ skipCache: true });
    }
  }, [modalDataReturn]);

  const openTempWorkerInfo = (id, target = '') => {
    setTarget(target);
    setObjID(id);
    toggleSlideOver('get_temp_worker');
  };

  const refuseTempWorker = (idTempWorker) => {
    let tempWorkerData = null;
    let keyFound = null;
    let foundInRefused = false;

    for (const key in searchTempWorkerAssignationRefusedResult) {
      if (searchTempWorkerAssignationRefusedResult.hasOwnProperty(key)) {
        const index = searchTempWorkerAssignationRefusedResult[key].findIndex(
          (data) => data.id_user === idTempWorker
        );
        if (index !== -1) {
          tempWorkerData = searchTempWorkerAssignationRefusedResult[key][index];
          keyFound = key;
          foundInRefused = true;

          const newRefusedArray = [
            ...searchTempWorkerAssignationRefusedResult[key],
          ];
          newRefusedArray.splice(index, 1);
          setSearchTempWorkerAssignationRefusedResult((prevData) => ({
            ...prevData,
            [key]: newRefusedArray,
          }));
          break;
        }
      }
    }

    if (foundInRefused) {
      const assignationArray =
        searchTempWorkerAssignationResult[keyFound] || [];

      const newAssignationArray = [
        ...assignationArray,
        { ...tempWorkerData, refused: false },
      ];

      newAssignationArray.sort((a, b) => {
        const lastnameA = a.lastname?.toLowerCase() || '';
        const lastnameB = b.lastname?.toLowerCase() || '';
        return lastnameA.localeCompare(lastnameB);
      });

      setSearchTempWorkerAssignationResult((prevData) => ({
        ...prevData,
        [keyFound]: newAssignationArray,
      }));
    } else {
      for (const key in searchTempWorkerAssignationResult) {
        if (searchTempWorkerAssignationResult.hasOwnProperty(key)) {
          const index = searchTempWorkerAssignationResult[key].findIndex(
            (data) => data.id_user === idTempWorker
          );
          if (index !== -1) {
            tempWorkerData = searchTempWorkerAssignationResult[key][index];
            keyFound = key;

            const newAssignationArray = [
              ...searchTempWorkerAssignationResult[key],
            ];
            newAssignationArray.splice(index, 1);
            setSearchTempWorkerAssignationResult((prevData) => ({
              ...prevData,
              [key]: newAssignationArray,
            }));
            break;
          }
        }
      }
      if (tempWorkerData) {
        const refusedArray =
          searchTempWorkerAssignationRefusedResult[keyFound] || [];

        const newRefusedArray = [
          ...refusedArray,
          { ...tempWorkerData, refused: true },
        ];

        newRefusedArray.sort((a, b) => {
          const lastnameA = a.lastname?.toLowerCase() || '';
          const lastnameB = b.lastname?.toLowerCase() || '';
          return lastnameA.localeCompare(lastnameB);
        });

        setSearchTempWorkerAssignationRefusedResult((prevData) => ({
          ...prevData,
          [keyFound]: newRefusedArray,
        }));
      }
    }

    setInfoMission((prevData) => ({
      ...prevData,
      users: prevData?.users.map((user) =>
        user.user_id === idTempWorker
          ? { ...user, refused: !user.refused }
          : user
      ),
    }));

    setSearchMissionResult((prevData) => {
      return prevData.map((mission) => {
        if (mission.id_mission_real === missionId) {
          const userExists = mission.users.some(
            (user) => user.user_id === idTempWorker
          );

          let updatedUsers;
          if (userExists) {
            updatedUsers = mission.users.map((user) =>
              user.user_id === idTempWorker
                ? { ...user, refused: !user.refused }
                : user
            );
          } else {
            const newUser = { user_id: idTempWorker, refused: true };
            updatedUsers = [...mission.users, newUser];
          }

          return {
            ...mission,
            users: updatedUsers,
          };
        } else {
          return mission;
        }
      });
    });

    ApiDatabase.postTempWorkerAssignationRefused(
      { token, idMission: infoMission._id, idTempWorker },
      (data) => {},
      (err) => {}
    );
  };

  const deletedContractHistory = (contractHistoryId) => {
    setInfoMission((prevData) => {
      const updatedContractHistories = prevData.contractHistories.map(
        (contractHistory) => {
          if (contractHistory._id === contractHistoryId) {
            return { ...contractHistory, status: 'deleted' };
          }
          return contractHistory;
        }
      );
      return { ...prevData, contractHistories: updatedContractHistories };
    });

    ApiDatabase.postDeleteContractHistory(
      { token, idContract: contractHistoryId },
      (data) => {},
      (err) => {}
    );
  };

  useEffect(() => {
    if (infoMission) {
      ApiDatabase.getQualificationsActive(
        { token },
        (data) => {
          const updatedData = data.map((item) => ({
            ...item,
            checked: item.name === infoMission.title,
          }));

          setQualifications(updatedData);
        },
        (err) => {}
      );
    }
  }, [infoMission]);

  const updateQualification = (updatedQualification) => {
    const updatedQualifications = [...qualifications];

    for (let i = 0; i < updatedQualifications.length; i++) {
      for (let j = 0; j < updatedQualification.length; j++) {
        if (updatedQualifications[i]._id === updatedQualification[j].value) {
          updatedQualifications[i].checked = updatedQualification[j].checked;
        }
      }
    }

    setQualifications(updatedQualifications);
  };

  useEffect(() => {
    if (infoMission) {
      setAddressMissionDetail(infoMission.address);
      setModifyMissionDetailData({
        qualification: infoMission.title,
        period: {
          start: dateToEpoch(infoMission.duration.datestart),
          end: dateToEpoch(infoMission.duration.dateend),
        },
        schedule: infoMission.schedules,
        salary: infoMission.salary.wage_per_hour,
        address: infoMission.address,
        reason: {
          reason: infoMission.reason_detail.reason,
          type: infoMission.reason_detail.type,
          proof: infoMission.reason_detail.proof || null,
          proof_firstname: infoMission.reason_detail.proof_firstname || null,
          proof_lastname: infoMission.reason_detail.proof_lastname || null,
          proof_description:
            infoMission.reason_detail.proof_description || null,
          proof_date: infoMission.reason_detail.proof_date || null,
        },
        licences: infoMission.licences,
        languages: infoMission.language,
        tools: infoMission.tools,
        reference: infoMission.reference,
        other: infoMission.other,
        other_interim: infoMission.other_interim,
      });

      if (modifyMissionDetail) {
        ApiDatabase.getAllLicence(
          (data) => {
            const updatedData = data.map((item) => ({
              name: item.name,
              checked: infoMission.licences.includes(item.name),
            }));

            setLicences(updatedData);
          },
          (err) => {
            console.warn(err);
          }
        );
        ApiDatabase.getListTools(
          (data) => {
            const updatedData = data.map((item) => ({
              name: item.name,
              checked: infoMission.tools.includes(item.name),
            }));

            setTools(updatedData);
          },
          (err) => {
            console.warn(err);
          }
        );
        ApiDatabase.getListLanguages(
          (data) => {
            const updatedData = data.map((item) => ({
              name: item.name,
              checked: infoMission.language.includes(item.name),
            }));

            setLang(updatedData);
          },
          (err) => {
            console.warn(err);
          }
        );
      }
    }
  }, [infoMission, modifyMissionDetail]);

  useEffect(() => {
    if (addressMissionDetail) {
      setModifyMissionDetailData((prevData) => ({
        ...prevData,
        address: addressMissionDetail,
      }));
    }
  }, [addressMissionDetail]);

  useEffect(() => {
    const checkedQualification = qualifications.find(
      (qualification) => qualification.checked === true
    );
    if (checkedQualification) {
      setModifyMissionDetailData((prevData) => ({
        ...prevData,
        qualification: checkedQualification.name,
      }));
    }
  }, [qualifications]);

  const updateLicence = (updatedLicence) => {
    const updatedLicences = [...licences];

    for (let i = 0; i < updatedLicences.length; i++) {
      for (let j = 0; j < updatedLicence.length; j++) {
        if (updatedLicences[i].name === updatedLicence[j].value) {
          updatedLicences[i].checked = updatedLicence[j].checked;
        }
      }
    }

    setLicences(updatedLicences);
  };

  useEffect(() => {
    const licencesChecked = licences
      .filter((licence) => licence.checked)
      .map((licence) => licence.name);

    setModifyMissionDetailData((prevData) => ({
      ...prevData,
      licences: licencesChecked,
    }));
  }, [licences]);

  const updateTool = (updatedTool) => {
    const updatedTools = [...tools];

    for (let i = 0; i < updatedTools.length; i++) {
      for (let j = 0; j < updatedTool.length; j++) {
        if (updatedTools[i].name === updatedTool[j].value) {
          updatedTools[i].checked = updatedTool[j].checked;
        }
      }
    }

    setTools(updatedTools);
  };

  useEffect(() => {
    const toolsChecked = tools
      .filter((tool) => tool.checked)
      .map((tool) => tool.name);

    setModifyMissionDetailData((prevData) => ({
      ...prevData,
      tools: toolsChecked,
    }));
  }, [tools]);

  const updateLanguage = (updatedLanguage) => {
    const updatedLanguages = [...lang];

    for (let i = 0; i < updatedLanguages.length; i++) {
      for (let j = 0; j < updatedLanguage.length; j++) {
        if (updatedLanguages[i].name === updatedLanguage[j].value) {
          updatedLanguages[i].checked = updatedLanguage[j].checked;
        }
      }
    }

    setLang(updatedLanguages);
  };

  useEffect(() => {
    const langChecked = lang
      .filter((language) => language.checked)
      .map((language) => language.name);

    setModifyMissionDetailData((prevData) => ({
      ...prevData,
      languages: langChecked,
    }));
  }, [lang]);

  const editMissionDetailReason = () => {
    toggle('mission_detail_reason');
  };

  const detectChanges = () => {
    const changes = [];
    let needsNewContract = false;
    let hasChanges = false;

    if (infoMission && modifyMissionDetailData.qualification) {
      const fieldsRequiringNewContract = [];

      const addChange = (field, before, after) => {
        if (
          fieldsRequiringNewContract.includes(field) &&
          infoMission.user_assigned
        ) {
          needsNewContract = true;
        }
        hasChanges = true;
        changes.push({ field, before, after });
      };

      const arraysAreEqual = (a, b) => {
        if (!Array.isArray(a) || !Array.isArray(b)) return false;
        if (a.length !== b.length) return false;
        const aSorted = [...a].sort();
        const bSorted = [...b].sort();
        for (let i = 0; i < aSorted.length; i++) {
          if (aSorted[i] !== bSorted[i]) return false;
        }
        return true;
      };

      if (modifyMissionDetailData.qualification !== infoMission.title) {
        addChange(
          'qualification',
          infoMission.title,
          modifyMissionDetailData.qualification
        );
      }

      const originalStart = dateToEpoch(infoMission.duration.datestart);
      const originalEnd = dateToEpoch(infoMission.duration.dateend);
      const originalStartGetTime = originalStart.getTime();
      const originalEndGetTime = originalEnd.getTime();
      const modifyMissionDetailStart = modifyMissionDetailData.period.start;
      const modifyMissionDetailEnd = modifyMissionDetailData.period.end;
      const modifyMissionDetailStartGetTime =
        modifyMissionDetailStart.getTime();
      const modifyMissionDetailEndGetTime = modifyMissionDetailEnd.getTime();

      if (
        modifyMissionDetailStartGetTime !== originalStartGetTime ||
        modifyMissionDetailEndGetTime !== originalEndGetTime
      ) {
        addChange(
          'period',
          { start: originalStart, end: originalEnd },
          {
            start: dateToIsoString(modifyMissionDetailStart),
            end: dateToIsoString(modifyMissionDetailEnd),
          }
        );
      }

      if (
        modifyMissionDetailData.schedule.length !== infoMission.schedules.length
      ) {
        addChange(
          'schedule',
          infoMission.schedules,
          modifyMissionDetailData.schedule
        );
      } else {
        outerLoop: for (
          let i = 0;
          i < modifyMissionDetailData.schedule.length;
          i++
        ) {
          const modifyScheduleDays = modifyMissionDetailData.schedule[i].days;
          const infoScheduleDays = infoMission.schedules[i].days;

          const filterDaysWithPeriods = (days) =>
            days.filter(
              (day) => day.periods && Object.keys(day.periods).length > 0
            );

          const modifyScheduleDaysWithPeriods =
            filterDaysWithPeriods(modifyScheduleDays);
          const infoScheduleDaysWithPeriods =
            filterDaysWithPeriods(infoScheduleDays);

          if (
            modifyScheduleDaysWithPeriods.length !==
            infoScheduleDaysWithPeriods.length
          ) {
            addChange(
              'schedule',
              infoMission.schedules,
              modifyMissionDetailData.schedule
            );
            break;
          }

          const sortDays = (days) =>
            [...days].sort((a, b) => dateToEpoch(a.date) - dateToEpoch(b.date));

          const modifyDaysSorted = sortDays(modifyScheduleDaysWithPeriods);
          const infoDaysSorted = sortDays(infoScheduleDaysWithPeriods);

          for (let j = 0; j < modifyDaysSorted.length; j++) {
            const modifyDay = modifyDaysSorted[j];
            const infoDay = infoDaysSorted[j];

            if (
              dateToEpoch(modifyDay.date).getTime() !==
              dateToEpoch(infoDay.date).getTime()
            ) {
              addChange(
                'schedule',
                infoMission.schedules,
                modifyMissionDetailData.schedule
              );
              break outerLoop;
            }

            const modifyPeriods = modifyDay.periods;
            const infoPeriods = infoDay.periods;

            if (!modifyPeriods || !infoPeriods) {
              addChange(
                'schedule',
                infoMission.schedules,
                modifyMissionDetailData.schedule
              );
              break outerLoop;
            }

            const periodKeys = [
              'periode_one_start',
              'periode_one_end',
              'periode_two_start',
              'periode_two_end',
              'periode_three_start',
              'periode_three_end',
            ];

            for (const key of periodKeys) {
              if (modifyPeriods[key] !== infoPeriods[key]) {
                addChange(
                  'schedule',
                  infoMission.schedules,
                  modifyMissionDetailData.schedule
                );
                break outerLoop;
              }
            }
          }
        }
      }

      if (
        parseFloat(modifyMissionDetailData.salary) !==
        parseFloat(infoMission.salary.wage_per_hour)
      ) {
        addChange(
          'salary',
          infoMission.salary.wage_per_hour,
          modifyMissionDetailData.salary
        );
      }

      if (modifyMissionDetailData.address !== infoMission.address) {
        addChange(
          'address',
          infoMission.address,
          modifyMissionDetailData.address
        );
      }

      const reasonFields = [
        'reason',
        'type',
        'proof',
        'proof_firstname',
        'proof_lastname',
        'proof_description',
        'proof_date',
      ];

      const reasonDetailChanged = reasonFields.some(
        (field) =>
          modifyMissionDetailData.reason[field] !==
          (infoMission.reason_detail[field] || null)
      );

      if (reasonDetailChanged) {
        addChange(
          'reason',
          infoMission.reason_detail,
          modifyMissionDetailData.reason
        );
      }

      if (
        !arraysAreEqual(modifyMissionDetailData.licences, infoMission.licences)
      ) {
        addChange(
          'licences',
          infoMission.licences,
          modifyMissionDetailData.licences
        );
      }

      if (
        !arraysAreEqual(modifyMissionDetailData.languages, infoMission.language)
      ) {
        addChange(
          'languages',
          infoMission.language,
          modifyMissionDetailData.languages
        );
      }

      if (!arraysAreEqual(modifyMissionDetailData.tools, infoMission.tools)) {
        addChange('tools', infoMission.tools, modifyMissionDetailData.tools);
      }

      if (
        (modifyMissionDetailData.reference || '') !==
        (infoMission.reference || '')
      ) {
        addChange(
          'reference',
          infoMission.reference,
          modifyMissionDetailData.reference
        );
      }

      if ((modifyMissionDetailData.other || '') !== (infoMission.other || '')) {
        addChange('other', infoMission.other, modifyMissionDetailData.other);
      }

      if (
        (modifyMissionDetailData.other_interim || '') !==
        (infoMission.other_interim || '')
      ) {
        addChange(
          'other_interim',
          infoMission.other_interim,
          modifyMissionDetailData.other_interim
        );
      }
    }

    return { hasChanges, changes, needsNewContract };
  };

  useEffect(() => {
    const { hasChanges, changes, needsNewContract } = detectChanges();
    setIsChangesModifyMissionDetail(hasChanges);
    setListAllChangesModifyMissionDetail(changes);
    setIsChangesNeedsNewContractModifyMissionDetail(needsNewContract);
  }, [modifyMissionDetailData]);

  const submitModifyMissionDetail = () => {
    const { hasChanges, changes, needsNewContract } = detectChanges();

    if (hasChanges && (role === 500 || role === 515)) {
      setObjID(infoMission._id);
      setListChangesMissionDetail(changes);
      setIsChangesNeedsNewContractMissionDetail(needsNewContract);
      toggle('mission_detail_submit');
    }
  };

  useEffect(() => {
    let handler = (e) => {
      if (
        dropDownNotificationRef.current &&
        !dropDownNotificationRef.current.contains(e.target)
      ) {
        setDropdownNotificationOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [dropDownNotificationRef]);

  // Pour l'Identity
  const identityData = infoMission.user_assigned_infos?.tempworker?.identity;
  const identityTitle =
    identityData?.type === 'Passeport' ? 'Passeport' : "Carte d'identité";

  let identityFilePathFinal = null;
  const identityFilePath1 =
    process.env.REACT_APP_FILE + identityData?.fileR?.filePath;
  const identityFilePath2 = identityData?.fileV?.filePath
    ? process.env.REACT_APP_FILE + identityData?.fileV?.filePath
    : null;

  if (identityFilePath2 === null)
    identityFilePathFinal = { filePath1: identityFilePath1 };
  else
    identityFilePathFinal = {
      filePath1: identityFilePath1,
      filePath2: identityFilePath2,
    };

  const identityValidatedType =
    identityData?.validated === '2' ? '5' : identityData?.validated;

  const identityDetails = [
    {
      label: "Numéro d'identité",
      value: identityData?.number_identity,
    },
    {
      label: 'Ville de naissance',
      value: identityData?.birthplace,
    },
    {
      label: 'Validité',
      value: `${
        identityData?.start_validity
          ? dateWordShort(identityData.start_validity)
          : 'NaN'
      } - ${
        identityData?.end_validity
          ? dateWordShort(identityData.end_validity)
          : 'NaN'
      }`,
    },
  ];

  // Pour le Visa
  const visaData = infoMission.user_assigned_infos?.tempworker?.visa;
  const visaTitle = visaData?.type === 'Visa' ? 'Visa' : 'Titre de séjour';

  let visaFilePathFinal = null;
  const visaFilePath1 = process.env.REACT_APP_FILE + visaData?.fileR?.filePath;
  const visaFilePath2 = visaData?.fileV?.filePath
    ? process.env.REACT_APP_FILE + visaData?.fileV?.filePath
    : null;

  if (visaFilePath2 === null) visaFilePathFinal = { filePath1: visaFilePath1 };
  else
    visaFilePathFinal = {
      filePath1: visaFilePath1,
      filePath2: visaFilePath2,
    };

  const visaValidatedType =
    visaData?.validated === '2' ? '5' : visaData?.validated;

  const visaDetails = [
    {
      label: 'Numéro',
      value: visaData?.number_visa,
    },
    {
      label: 'Validité',
      value: `${
        visaData?.start_validity
          ? dateWordShort(visaData.start_validity)
          : 'NaN'
      } - ${
        visaData?.end_validity ? dateWordShort(visaData.end_validity) : 'NaN'
      }`,
    },
  ];

  // Pour la Carte Vitale
  const cardVitalityData =
    infoMission.user_assigned_infos?.tempworker?.card_vitality;
  const cardVitalityTitle = 'Carte vitale';

  let cardVitalityFilePathFinal = null;
  const cardVitalityFilePath1 =
    process.env.REACT_APP_FILE + cardVitalityData?.file?.filePath;
  if (cardVitalityFilePath1)
    cardVitalityFilePathFinal = { filePath1: cardVitalityFilePath1 };

  const cardVitalityValidatedType =
    cardVitalityData?.validated === '2' ? '5' : cardVitalityData?.validated;

  const cardVitalityDetails = [
    {
      label: 'Numéro',
      value: cardVitalityData?.social_security,
    },
  ];

  // Pour le Justificatif d'adresse
  const proofOfAddressData =
    infoMission.user_assigned_infos?.tempworker?.proof_of_address;
  const proofOfAddressTitle = "Justificatif d'adresse";

  let proofOfAddressFilePathFinal = null;
  const proofOfAddressFilePath1 =
    process.env.REACT_APP_FILE + proofOfAddressData?.file?.filePath;
  if (proofOfAddressFilePath1)
    proofOfAddressFilePathFinal = { filePath1: proofOfAddressFilePath1 };

  const proofOfAddressValidatedType =
    proofOfAddressData?.validated === '2' ? '5' : proofOfAddressData?.validated;

  // Pour l'Attestation d'hébergement
  const accommodationData =
    infoMission.user_assigned_infos?.tempworker?.accommodation_of_address;
  const accommodationTitle = "Attestation d'hébergement";

  let accommodationFilePathFinal = null;
  const accommodationFilePath1 =
    process.env.REACT_APP_FILE + accommodationData?.file?.filePath;
  if (accommodationFilePath1)
    accommodationFilePathFinal = { filePath1: accommodationFilePath1 };

  const accommodationValidatedType =
    accommodationData?.validated === '2' ? '5' : accommodationData?.validated;

  const openDocument = (type, type2, uri) => {
    setAdminLink(uri);
    setAdminType(type);

    if (type2 === 'view') {
      setAdminTempWorkerId('');
      toggle('ad_file_view');
    } else {
      setAdminTempWorkerId(infoMission.user_assigned_infos?.tempworker?._id);
      if (type === 'identity') toggle('ad_file_accept_identity');
      else if (type === 'visa') toggle('ad_file_accept_visa');
      else if (type === 'card_vitality') toggle('ad_file_accept_card_vitality');
      else if (type === 'proof_of_address') toggle('ad_file_accept_simple');
      else if (type === 'accommodation_of_address')
        toggle('ad_file_accept_simple');
      else toggle('ad_file_view');
    }
  };

  return (
    <>
      {isInfoMissionWaiting ? (
        <>
          <div
            className={'h-full border rounded overflow-y-auto animate-pulse'}
          >
            <div className={'flex flex-col gap-4 p-4 h-full'}>
              <div className={'flex gap-1 justify-between items-center'}>
                <div className={'flex flex-col gap-2'}>
                  <div
                    className={
                      'flex gap-1 items-center font-black text-lg flex-1 min-w-0'
                    }
                  >
                    <div>
                      <div className={'h-6 bg-gray-200 rounded w-56'}></div>
                    </div>
                    <div>
                      <div className={'h-6 bg-gray-200 rounded w-8'}></div>
                    </div>
                  </div>
                  <div className={'text-sm text-gray-700'}>
                    <div className={'h-4 bg-gray-200 rounded w-36'}></div>
                  </div>
                </div>
                <div className={'flex gap-2'}>
                  <div className={'h-6 bg-gray-200 rounded w-6'}></div>
                  <div className={'h-6 bg-gray-200 rounded w-6'}></div>
                  <div className={'h-6 bg-gray-200 rounded w-6'}></div>
                </div>
              </div>
              <Divider />
              <div className={'flex gap-2 w-full text-sm'}>
                <div className={`cursor-pointer pt-1 pb-2 px-2`}>
                  <div className={'h-4 bg-gray-200 rounded w-24'}></div>
                </div>
                <div className={`cursor-pointer pt-1 pb-2 px-2`}>
                  <div className={'h-4 bg-gray-200 rounded w-24'}></div>
                </div>
                <div className={`cursor-pointer pt-1 pb-2 px-2`}>
                  <div className={'h-4 bg-gray-200 rounded w-24'}></div>
                </div>
              </div>
              <div className={'w-full'}>
                <div className={'flex gap-4 ft-sm'}>
                  <div className={`my-auto px-3 py-2 rounded cursor-pointer`}>
                    <div className={'flex gap-1'}>
                      <div className={'my-auto'}>
                        <div className={'h-4 bg-gray-200 rounded w-24'}></div>
                      </div>
                    </div>
                  </div>
                  <div className={`my-auto px-3 py-2 rounded cursor-pointer`}>
                    <div className={'flex gap-1'}>
                      <div className={'my-auto'}>
                        <div className={'h-4 bg-gray-200 rounded w-24'}></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`my-auto text-gray-500 px-3 py-2 rounded cursor-pointer`}
                  >
                    <div className={'h-6 bg-gray-200 rounded w-6'}></div>
                  </div>
                  <div
                    className={`my-auto ml-auto px-3 py-2 rounded cursor-pointer`}
                  >
                    <div className={'flex gap-1'}>
                      <div className={'my-auto'}>
                        <div className={'h-4 bg-gray-200 rounded w-24'}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'flex flex-col gap-2'}>
                <div className={'h-4 bg-gray-200 rounded w-16'}></div>
                <div className={'h-8 bg-gray-200 rounded w-full'}></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {infoMission ? (
            <>
              <div className={'h-full border rounded overflow-y-auto'}>
                <div className={'flex flex-col gap-4 p-4 h-full'}>
                  <div className={'flex gap-1 justify-between items-center'}>
                    <div className={'flex flex-col'}>
                      <div
                        className={
                          'flex gap-1 items-center font-black text-lg flex-1 min-w-0'
                        }
                      >
                        <div>{infoMission.title}</div>
                        <div>n°{infoMission.id_mission}</div>
                      </div>
                      <div className={'text-sm text-gray-700'}>
                        {infoMission.company?.name_commercial}
                      </div>
                    </div>
                    <div className={'flex gap-2'}>
                      <CopyToClipboard
                        text={`${process.env.REACT_APP_INTERIM}ficheMissionSearch/${infoMission._id}`}
                      >
                        <Button size={'SMI'} color={'DEFAULT'}>
                          <LinkIcon wh={20} color={'#374151'} />
                        </Button>
                      </CopyToClipboard>
                      {shouldShowTrashButton(infoMission) === 'delete' && (
                        <Button
                          size={'SMI'}
                          color={'DEFAULT'}
                          onClick={handleDeleteMission}
                        >
                          <TrashIcon wh={20} color={'#374151'} />
                        </Button>
                      )}
                      {shouldShowTrashButton(infoMission) === 'cancel' && (
                        <Button
                          size={'SMI'}
                          color={'DEFAULT'}
                          onClick={handleCancelMission}
                        >
                          <TrashIcon wh={20} color={'#374151'} />
                        </Button>
                      )}
                      {infoMission.status !== 'cancel' && (
                        <div
                          className={'relative'}
                          ref={dropDownNotificationRef}
                          onClick={() => {
                            setDropdownNotificationOpen(true);
                          }}
                        >
                          <Button size={'SMI'} color={'PRIMARY'}>
                            <div className={'flex gap-1'}>
                              <BellIcon wh={20} color={'#FFFFFF'} />
                              Notifier
                            </div>
                          </Button>
                          {dropdownNotificationOpen && (
                            <div
                              className={
                                'absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 right-0 bg-gray-50'
                              }
                            >
                              <div
                                className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer hover:bg-gray-100"
                                onClick={() => {
                                  setObjID(infoMission?._id);
                                  toggle('mission_notify');
                                }}
                              >
                                Notifier la mission
                              </div>
                              <div
                                className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer hover:bg-gray-100"
                                onClick={() => {
                                  setObjID(infoMission?._id);
                                  toggle('mission_notify_global');
                                }}
                              >
                                Notification globale
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider />
                  {infoMission.status === 'cancel' ? (
                    <div className={'flex flex-col gap-3'}>
                      <h2 className={'ft-b text-lg'}>Supprimer la mission</h2>
                      <div className={'flex gap-1 text-gray-500'}>
                        Raison de l'annulation :{' '}
                        <div className={'text-gray-900'}>
                          {infoMission.reason}
                        </div>
                      </div>
                      {infoMission.contract_company[0]?.id_bestt && (
                        <Banner type="warning">
                          Vous devez supprimer manuellement le contrat n°
                          {infoMission.contract_company[0]?.id_bestt} sur Bestt
                          avant de supprimer la mission.
                        </Banner>
                      )}
                      <div className={'w-fit'}>
                        <Button
                          size={'SM'}
                          color={'PRIMARY'}
                          onClick={handleDeleteMission}
                        >
                          Supprimer la mission
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {(role === 500 || role === 515) && (
                        <>
                          <div className={'flex gap-2 w-full text-sm'}>
                            <div
                              className={`cursor-pointer pt-1 pb-2 px-2 border-b ${
                                missionPage === 1
                                  ? 'border-yellow-500 text-yellow-500'
                                  : 'hover:border-yellow-500 hover:text-yellow-500 text-gray-500'
                              }`}
                              onClick={() => setMissionPage(1)}
                            >
                              {infoMission.user_assigned
                                ? 'Historique de candidature'
                                : 'Candidature'}
                            </div>
                            <div
                              className={`cursor-pointer pt-1 pb-2 px-2 border-b ${
                                missionPage === 2
                                  ? 'border-yellow-500 text-yellow-500'
                                  : 'hover:border-yellow-500 hover:text-yellow-500 text-gray-500'
                              }`}
                              onClick={() => setMissionPage(2)}
                            >
                              Contrats
                            </div>
                            <div
                              className={`cursor-pointer pt-1 pb-2 px-2 border-b ${
                                missionPage === 3
                                  ? 'border-yellow-500 text-yellow-500'
                                  : 'hover:border-yellow-500 hover:text-yellow-500 text-gray-500'
                              }`}
                              onClick={() => setMissionPage(3)}
                            >
                              Détail de mission
                            </div>
                          </div>
                          {missionPage === 1 && (
                            <>
                              <div className={'w-full'}>
                                <div className={'flex gap-4 ft-sm'}>
                                  <div
                                    className={`my-auto px-3 py-2 rounded cursor-pointer ${
                                      typeSearchTempWorkerAssignation === 1
                                        ? 'text-gray-700 bg-gray-100'
                                        : 'text-gray-500'
                                    }`}
                                    onClick={() =>
                                      setTypeSearchTempWorkerAssignation(1)
                                    }
                                  >
                                    <div className={'flex gap-1'}>
                                      <div className={'my-auto'}>
                                        Candidature
                                      </div>
                                      <div
                                        className={
                                          'my-auto bg-yellow-100 text-yellow-800 rounded px-1 flex items-center justify-center min-w-[20px]'
                                        }
                                      >
                                        {countTempWorkerPostulate}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`my-auto px-3 py-2 rounded cursor-pointer ${
                                      typeSearchTempWorkerAssignation === 2
                                        ? 'text-gray-700 bg-gray-100'
                                        : 'text-gray-500'
                                    }`}
                                    onClick={() =>
                                      setTypeSearchTempWorkerAssignation(2)
                                    }
                                  >
                                    <div className={'flex gap-1'}>
                                      <div className={'my-auto'}>Refusé</div>
                                      <div
                                        className={
                                          'my-auto bg-yellow-100 text-yellow-800 rounded px-1 flex items-center justify-center min-w-[20px]'
                                        }
                                      >
                                        {countTempWorkerRefused}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`my-auto text-gray-500 px-3 py-2 rounded cursor-pointer ${
                                      typeSearchTempWorkerAssignation === 3
                                        ? 'text-gray-700 bg-gray-100'
                                        : 'text-gray-500'
                                    }`}
                                    onClick={() =>
                                      setTypeSearchTempWorkerAssignation(3)
                                    }
                                  >
                                    <SearchLoop wh={20} color={'#6B7280'} />
                                  </div>
                                  <div
                                    className={`my-auto ml-auto px-3 py-2 rounded cursor-pointer ${
                                      typeSearchTempWorkerAssignation === 4
                                        ? 'text-gray-700 bg-gray-100'
                                        : 'text-gray-500'
                                    }`}
                                    onClick={() =>
                                      setTypeSearchTempWorkerAssignation(4)
                                    }
                                  >
                                    <div className={'flex gap-1'}>
                                      <div className={'my-auto'}>
                                        Invitation
                                      </div>
                                      <div
                                        className={
                                          'my-auto bg-yellow-100 text-yellow-800 rounded px-1 flex items-center justify-center min-w-[20px]'
                                        }
                                      >
                                        {countUsersInvite}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*Postulate search*/}
                              {typeSearchTempWorkerAssignation === 1 && (
                                <>
                                  <label className="ft-sm text-gray-500 flex flex-col gap-1 text-sm">
                                    <div>Intérimaire</div>
                                    <input
                                      type="text"
                                      className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg"
                                      value={searchTempWorkerAssignation}
                                      onChange={(e) =>
                                        setSearchTempWorkerAssignation(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                  {searchTempWorkerAssignationResultLoadData ? (
                                    <>
                                      {searchTempWorkerAssignationResultWaiting ? (
                                        <div
                                          className={
                                            'flex flex-col animate-pulse'
                                          }
                                        >
                                          {[1, 2, 3, 4, 5].map((i, index) => (
                                            <div
                                              key={index}
                                              className={
                                                'w-full flex flex-col gap-5 px-2 py-4 border-b cursor-pointer'
                                              }
                                            >
                                              <div
                                                className={
                                                  'flex flex-col gap-2'
                                                }
                                              >
                                                <div
                                                  className={'text-gray-500'}
                                                >
                                                  <div
                                                    className={
                                                      'flex flex-col gap-3'
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        'w-full text-sm'
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          'flex flex-col gap-1'
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            'flex gap-1 items-center w-full flex-1 min-w-0'
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              'text-base text-gray-900 truncate'
                                                            }
                                                          >
                                                            <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                          </div>
                                                        </div>
                                                        <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                        <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={'flex gap-1'}
                                                    >
                                                      <div className="w-12 h-5 bg-gray-200 rounded"></div>
                                                      <div className="w-12 h-5 bg-gray-200 rounded"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        <>
                                          {searchTempWorkerAssignationToDisplay ? (
                                            <>
                                              <div
                                                className={
                                                  'flex flex-col gap-2 overflow-y-auto w-full my-2'
                                                }
                                              >
                                                {searchTempWorkerAssignationToDisplay
                                                  .complete?.length > 0 ||
                                                searchTempWorkerAssignationToDisplay
                                                  .partial?.length > 0 ||
                                                searchTempWorkerAssignationToDisplay
                                                  .not_updated?.length > 0 ||
                                                searchTempWorkerAssignationToDisplay
                                                  .incomplete?.length > 0 ? (
                                                  <>
                                                    {searchTempWorkerAssignationToDisplay
                                                      .complete.length > 0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultCompleteOpen(
                                                                !isTempWorkerResultCompleteOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              DISPONIBILITÉ
                                                              COMPLÈTE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultCompleteOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultCompleteOpen &&
                                                            searchTempWorkerAssignationToDisplay.complete?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                    onClickRefused:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        refuseTempWorker(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerAssignationToDisplay
                                                      .partial.length > 0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultPartialOpen(
                                                                !isTempWorkerResultPartialOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              DISPONIBILITÉ
                                                              PARTIELLE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultPartialOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultPartialOpen &&
                                                            searchTempWorkerAssignationToDisplay.partial?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                    onClickRefused:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        refuseTempWorker(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerAssignationToDisplay
                                                      .not_updated.length >
                                                      0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultNotUpdatedOpen(
                                                                !isTempWorkerResultNotUpdatedOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              NON MIS À JOUR
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultNotUpdatedOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultNotUpdatedOpen &&
                                                            searchTempWorkerAssignationToDisplay.not_updated?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                    onClickRefused:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        refuseTempWorker(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerAssignationToDisplay
                                                      .incomplete.length >
                                                      0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultIncompleteOpen(
                                                                !isTempWorkerResultIncompleteOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              NON DISPONIBLE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultIncompleteOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultIncompleteOpen &&
                                                            searchTempWorkerAssignationToDisplay.incomplete?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                    onClickRefused:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        refuseTempWorker(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>Pas d'intérimaire trouvé</>
                                                )}
                                              </div>
                                              <div className={''}>
                                                <Pagination
                                                  currentPage={
                                                    currentPageTempWorkerAssignation
                                                  }
                                                  countPerPage={
                                                    limitElementTempWorkerAssignation
                                                  }
                                                  countTotal={
                                                    countTotalTempWorkerAssignation
                                                  }
                                                  totalPages={
                                                    totalPagesTempWorkerAssignation
                                                  }
                                                  setCurrentPage={
                                                    setCurrentPageTempWorkerAssignation
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            <>Pas d'intérimaire trouvé</>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>la</>
                                  )}
                                </>
                              )}

                              {/*Postulate Refused search*/}
                              {typeSearchTempWorkerAssignation === 2 && (
                                <>
                                  <label className="ft-sm text-gray-500 flex flex-col gap-1 text-sm">
                                    <div>Intérimaire</div>
                                    <input
                                      type="text"
                                      className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg"
                                      value={searchTempWorkerAssignationRefused}
                                      onChange={(e) =>
                                        setSearchTempWorkerAssignationRefused(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                  {searchTempWorkerAssignationRefusedResultLoadData ? (
                                    <>
                                      {searchTempWorkerAssignationRefusedResultWaiting ? (
                                        <div
                                          className={
                                            'flex flex-col animate-pulse'
                                          }
                                        >
                                          {[1, 2, 3, 4, 5].map((i, index) => (
                                            <div
                                              key={index}
                                              className={
                                                'w-full flex flex-col gap-5 px-2 py-4 border-b cursor-pointer'
                                              }
                                            >
                                              <div
                                                className={
                                                  'flex flex-col gap-2'
                                                }
                                              >
                                                <div
                                                  className={'text-gray-500'}
                                                >
                                                  <div
                                                    className={
                                                      'flex flex-col gap-3'
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        'w-full text-sm'
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          'flex flex-col gap-1'
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            'flex gap-1 items-center w-full flex-1 min-w-0'
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              'text-base text-gray-900 truncate'
                                                            }
                                                          >
                                                            <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                          </div>
                                                        </div>
                                                        <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                        <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={'flex gap-1'}
                                                    >
                                                      <div className="w-12 h-5 bg-gray-200 rounded"></div>
                                                      <div className="w-12 h-5 bg-gray-200 rounded"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        <>
                                          {searchTempWorkerAssignationRefusedToDisplay ? (
                                            <>
                                              <div
                                                className={
                                                  'flex flex-col gap-2 overflow-y-auto w-full my-2'
                                                }
                                              >
                                                {searchTempWorkerAssignationRefusedToDisplay
                                                  .complete?.length > 0 ||
                                                searchTempWorkerAssignationRefusedToDisplay
                                                  .partial?.length > 0 ||
                                                searchTempWorkerAssignationRefusedToDisplay
                                                  .not_updated?.length > 0 ||
                                                searchTempWorkerAssignationRefusedToDisplay
                                                  .incomplete?.length > 0 ? (
                                                  <>
                                                    {searchTempWorkerAssignationRefusedToDisplay
                                                      .complete.length > 0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultCompleteOpen(
                                                                !isTempWorkerResultCompleteOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              DISPONIBILITÉ
                                                              COMPLÈTE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultCompleteOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultCompleteOpen &&
                                                            searchTempWorkerAssignationRefusedToDisplay.complete?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  isRefused
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                    onClickRefused:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        refuseTempWorker(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerAssignationRefusedToDisplay
                                                      .partial.length > 0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultPartialOpen(
                                                                !isTempWorkerResultPartialOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              DISPONIBILITÉ
                                                              PARTIELLE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultPartialOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultPartialOpen &&
                                                            searchTempWorkerAssignationRefusedToDisplay.partial?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  isRefused
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                    onClickRefused:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        refuseTempWorker(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerAssignationRefusedToDisplay
                                                      .not_updated.length >
                                                      0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultNotUpdatedOpen(
                                                                !isTempWorkerResultNotUpdatedOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              NON MIS À JOUR
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultNotUpdatedOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultNotUpdatedOpen &&
                                                            searchTempWorkerAssignationRefusedToDisplay.not_updated?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  isRefused
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                    onClickRefused:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        refuseTempWorker(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerAssignationRefusedToDisplay
                                                      .incomplete.length >
                                                      0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultIncompleteOpen(
                                                                !isTempWorkerResultIncompleteOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              NON DISPONIBLE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultIncompleteOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultIncompleteOpen &&
                                                            searchTempWorkerAssignationRefusedToDisplay.incomplete?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  isRefused
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                    onClickRefused:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        refuseTempWorker(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>Pas d'intérimaire trouvé</>
                                                )}
                                              </div>
                                              <div className={''}>
                                                <Pagination
                                                  currentPage={
                                                    currentPageTempWorkerAssignationRefused
                                                  }
                                                  countPerPage={
                                                    limitElementTempWorkerAssignationRefused
                                                  }
                                                  countTotal={
                                                    countTotalTempWorkerAssignationRefused
                                                  }
                                                  totalPages={
                                                    totalPagesTempWorkerAssignationRefused
                                                  }
                                                  setCurrentPage={
                                                    setCurrentPageTempWorkerAssignationRefused
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            <>Pas d'intérimaire trouvé</>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>la</>
                                  )}
                                </>
                              )}

                              {/*Candidate search*/}
                              {typeSearchTempWorkerAssignation === 3 && (
                                <>
                                  <label className="ft-sm text-gray-500 flex flex-col gap-1 text-sm">
                                    <div>Intérimaire</div>
                                    <input
                                      type="text"
                                      className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg"
                                      value={searchTempWorker}
                                      onChange={(e) =>
                                        setSearchTempWorker(e.target.value)
                                      }
                                    />
                                  </label>
                                  {searchTempWorkerResultLoadData ? (
                                    <>
                                      {searchTempWorkerResultWaiting ? (
                                        <>
                                          <div
                                            className={
                                              'flex flex-col animate-pulse'
                                            }
                                          >
                                            {[1, 2, 3, 4, 5].map((i, index) => (
                                              <div
                                                key={index}
                                                className={
                                                  'w-full flex flex-col gap-5 px-2 py-4 border-b cursor-pointer'
                                                }
                                              >
                                                <div
                                                  className={
                                                    'flex flex-col gap-2'
                                                  }
                                                >
                                                  <div
                                                    className={'text-gray-500'}
                                                  >
                                                    <div
                                                      className={
                                                        'flex flex-col gap-3'
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          'w-full text-sm'
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            'flex flex-col gap-1'
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              'flex gap-1 items-center w-full flex-1 min-w-0'
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'text-base text-gray-900 truncate'
                                                              }
                                                            >
                                                              <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                            </div>
                                                          </div>
                                                          <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                          <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={'flex gap-1'}
                                                      >
                                                        <div className="w-12 h-5 bg-gray-200 rounded"></div>
                                                        <div className="w-12 h-5 bg-gray-200 rounded"></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {searchTempWorkerResult ? (
                                            <>
                                              <div
                                                className={
                                                  'flex flex-col gap-2 overflow-y-auto w-full my-2'
                                                }
                                              >
                                                {searchTempWorkerResult.complete
                                                  ?.length > 0 ||
                                                searchTempWorkerResult.partial
                                                  ?.length > 0 ||
                                                searchTempWorkerResult
                                                  .not_updated?.length > 0 ||
                                                searchTempWorkerResult
                                                  .incomplete?.length > 0 ? (
                                                  <>
                                                    {searchTempWorkerResult
                                                      .complete.length > 0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultCompleteOpen(
                                                                !isTempWorkerResultCompleteOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              DISPONIBILITÉ
                                                              COMPLÈTE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultCompleteOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultCompleteOpen &&
                                                            searchTempWorkerResult.complete?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerResult
                                                      .partial.length > 0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultPartialOpen(
                                                                !isTempWorkerResultPartialOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              DISPONIBILITÉ
                                                              PARTIELLE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultPartialOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultPartialOpen &&
                                                            searchTempWorkerResult.partial?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerResult
                                                      .not_updated.length >
                                                      0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultNotUpdatedOpen(
                                                                !isTempWorkerResultNotUpdatedOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              NON MIS À JOUR
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultNotUpdatedOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          {isTempWorkerResultNotUpdatedOpen &&
                                                            searchTempWorkerResult.not_updated?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                    {searchTempWorkerResult
                                                      .incomplete.length >
                                                      0 && (
                                                      <>
                                                        <div
                                                          className={
                                                            'flex flex-col'
                                                          }
                                                        >
                                                          <Divider />
                                                          <div
                                                            className={
                                                              'flex justify-between cursor-pointer px-1 py-2'
                                                            }
                                                            onClick={() =>
                                                              setIsTempWorkerResultIncompleteOpen(
                                                                !isTempWorkerResultIncompleteOpen
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              NON DISPONIBLE
                                                            </div>
                                                            <div
                                                              className={
                                                                'my-auto'
                                                              }
                                                            >
                                                              {isTempWorkerResultIncompleteOpen ? (
                                                                <ChevronDown
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              ) : (
                                                                <ChevronTop
                                                                  wh={20}
                                                                  color={
                                                                    '#374151'
                                                                  }
                                                                />
                                                              )}
                                                            </div>
                                                          </div>
                                                          <Divider />
                                                          {isTempWorkerResultIncompleteOpen &&
                                                            searchTempWorkerResult.incomplete?.map(
                                                              (
                                                                tempWorker,
                                                                index
                                                              ) => (
                                                                <MissionDetailTempWorker
                                                                  index={index}
                                                                  tempWorker={
                                                                    tempWorker
                                                                  }
                                                                  selectedTempWorkerAssignation={
                                                                    selectedTempWorkerAssignation
                                                                  }
                                                                  setSelectedTempWorkerAssignation={
                                                                    setSelectedTempWorkerAssignation
                                                                  }
                                                                  selectedTempWorkerRefused={
                                                                    selectedTempWorkerRefused
                                                                  }
                                                                  setSelectedTempWorkerRefused={
                                                                    setSelectedTempWorkerRefused
                                                                  }
                                                                  missionDateStart={
                                                                    missionDateStart
                                                                  }
                                                                  missionDateEnd={
                                                                    missionDateEnd
                                                                  }
                                                                  {...(!infoMission.user_assigned && {
                                                                    onClickAssignation:
                                                                      (
                                                                        tempWorkerId
                                                                      ) =>
                                                                        assignationMissionSubmit(
                                                                          tempWorkerId
                                                                        ),
                                                                  })}
                                                                />
                                                              )
                                                            )}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>Pas d'intérimaire trouvé</>
                                                )}
                                              </div>
                                              <div className={''}>
                                                <Pagination
                                                  currentPage={
                                                    currentPageTempWorker
                                                  }
                                                  countPerPage={
                                                    limitElementTempWorker
                                                  }
                                                  countTotal={
                                                    countTotalTempWorker
                                                  }
                                                  totalPages={
                                                    totalPagesTempWorker
                                                  }
                                                  setCurrentPage={
                                                    setCurrentPageTempWorker
                                                  }
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            <>Pas d'intérimaire trouvé</>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>la</>
                                  )}
                                </>
                              )}
                              {/*Invite search*/}
                              {typeSearchTempWorkerAssignation === 4 && (
                                <>
                                  {searchTempWorkerInviteResultWaiting ? (
                                    <div
                                      className={'flex flex-col animate-pulse'}
                                    >
                                      {[1, 2, 3, 4, 5].map((i, index) => (
                                        <div
                                          key={index}
                                          className={
                                            'w-full flex flex-col gap-5 px-2 py-4 border-b cursor-pointer'
                                          }
                                        >
                                          <div
                                            className={'flex flex-col gap-2'}
                                          >
                                            <div className={'text-gray-500'}>
                                              <div
                                                className={
                                                  'flex flex-col gap-3'
                                                }
                                              >
                                                <div
                                                  className={'w-full text-sm'}
                                                >
                                                  <div
                                                    className={
                                                      'flex flex-col gap-1'
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        'flex gap-1 items-center w-full flex-1 min-w-0'
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          'text-base text-gray-900 truncate'
                                                        }
                                                      >
                                                        <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                      </div>
                                                    </div>
                                                    <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                    <div className="w-56 h-5 bg-gray-200 rounded"></div>
                                                  </div>
                                                </div>
                                                <div className={'flex gap-1'}>
                                                  <div className="w-12 h-5 bg-gray-200 rounded"></div>
                                                  <div className="w-12 h-5 bg-gray-200 rounded"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <>
                                      {searchTempWorkerInviteResult.length >
                                      0 ? (
                                        <div
                                          className={
                                            'flex flex-col gap-2 overflow-y-auto w-full my-2'
                                          }
                                        >
                                          {searchTempWorkerInviteResult.map(
                                            (tempWorkerInvite, index) => (
                                              <div
                                                index={index}
                                                className={
                                                  'border rounded p-2 cursor-pointer'
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  openTempWorkerInfo(
                                                    tempWorkerInvite._id
                                                  );
                                                }}
                                              >
                                                <div
                                                  className={
                                                    'flex justify-between items-center text-sm'
                                                  }
                                                >
                                                  <div className={''}>
                                                    <TempWorkerMission
                                                      user={tempWorkerInvite}
                                                    />
                                                  </div>
                                                  <div className={''}>
                                                    {tempWorkerInvite.status ===
                                                      'pending' && (
                                                      <Badge type={'WARNING'}>
                                                        En attente
                                                      </Badge>
                                                    )}
                                                    {tempWorkerInvite.status ===
                                                      'accepted' && (
                                                      <Badge type={'VALID'}>
                                                        Accepté
                                                      </Badge>
                                                    )}
                                                    {tempWorkerInvite.status ===
                                                      'refused' && (
                                                      <Badge type={'ALERT'}>
                                                        Refusé
                                                      </Badge>
                                                    )}
                                                    {tempWorkerInvite.status ===
                                                      'taken' && (
                                                      <Badge type={'ALERT'}>
                                                        Attribué
                                                      </Badge>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        'Aucun intérimaire invité'
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {missionPage === 2 && (
                            <div className={'flex flex-col gap-4'}>
                              <div className={'flex flex-col gap-2'}>
                                <div className={''}>Profil attribué</div>
                                {infoMission.user_assigned ? (
                                  <>
                                    <div
                                      className={
                                        'flex gap-4 justify-between items-center border rounded-md px-4 py-2.5 text-sm text-gray-500 cursor-pointer'
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        openTempWorkerInfo(
                                          infoMission.user_assigned
                                        );
                                      }}
                                    >
                                      <TempWorkerMission
                                        user={infoMission.user_assigned_infos}
                                      />
                                      <div className={''}>
                                        <div
                                          className={
                                            'rounded p-1 cursor-pointer'
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            unassignMission(
                                              infoMission._id,
                                              infoMission.user_assigned
                                            );
                                          }}
                                        >
                                          <Close wh={20} color={'#374151'} />
                                        </div>
                                      </div>
                                    </div>
                                    {(Number(
                                      infoMission.user_assigned_infos
                                        ?.tempworker?.identity?.validated
                                    ) !== 2 ||
                                      Number(
                                        infoMission.user_assigned_infos
                                          ?.tempworker?.visa?.validated
                                      ) !== 2 ||
                                      Number(
                                        infoMission.user_assigned_infos
                                          ?.tempworker?.card_vitality?.validated
                                      ) !== 2 ||
                                      Number(
                                        infoMission.user_assigned_infos
                                          ?.tempworker?.proof_of_address
                                          ?.validated
                                      ) !== 2 ||
                                      Number(
                                        infoMission.user_assigned_infos
                                          ?.tempworker?.accommodation_of_address
                                          ?.validated
                                      ) !== 2) && (
                                      <div
                                        className={
                                          'grid gap-2 grid-cols-1 md:grid-cols-2 text-sm'
                                        }
                                      >
                                        {/* Identité */}
                                        {Number(
                                          infoMission.user_assigned_infos
                                            .tempworker?.identity?.validated
                                        ) !== 2 && (
                                          <IdentityFileItem
                                            title={identityTitle}
                                            isOpen={isIdentityFileOpen.identity}
                                            onToggle={() => {
                                              setIsIdentityFileOpen(
                                                (prevData) => ({
                                                  ...prevData,
                                                  identity: !prevData.identity,
                                                })
                                              );
                                            }}
                                            openDocument={openDocument}
                                            id="identity"
                                            filePathFinal={
                                              identityFilePathFinal
                                            }
                                            validatedType={
                                              identityValidatedType
                                            }
                                            details={identityDetails}
                                          />
                                        )}
                                        {/* Visa */}
                                        {Number(
                                          infoMission.user_assigned_infos
                                            .tempworker?.visa?.validated
                                        ) !== 2 &&
                                          infoMission.user_assigned_infos
                                            .tempworker.visa?.type && (
                                            <IdentityFileItem
                                              title={visaTitle}
                                              isOpen={isIdentityFileOpen.visa}
                                              onToggle={() => {
                                                setIsIdentityFileOpen(
                                                  (prevData) => ({
                                                    ...prevData,
                                                    visa: !prevData.visa,
                                                  })
                                                );
                                              }}
                                              openDocument={openDocument}
                                              id="visa"
                                              filePathFinal={visaFilePathFinal}
                                              validatedType={visaValidatedType}
                                              details={visaDetails}
                                            />
                                          )}
                                        {/* Carte Vitale */}
                                        {Number(
                                          infoMission.user_assigned_infos
                                            .tempworker?.card_vitality
                                            ?.validated
                                        ) !== 2 && (
                                          <IdentityFileItem
                                            title={cardVitalityTitle}
                                            isOpen={
                                              isIdentityFileOpen.card_vitality
                                            }
                                            onToggle={() => {
                                              setIsIdentityFileOpen(
                                                (prevData) => ({
                                                  ...prevData,
                                                  card_vitality:
                                                    !prevData.card_vitality,
                                                })
                                              );
                                            }}
                                            openDocument={openDocument}
                                            id="card_vitality"
                                            filePathFinal={
                                              cardVitalityFilePathFinal
                                            }
                                            validatedType={
                                              cardVitalityValidatedType
                                            }
                                            details={cardVitalityDetails}
                                          />
                                        )}
                                        {/* Justificatif d'adresse */}
                                        {Number(
                                          infoMission.user_assigned_infos
                                            .tempworker?.proof_of_address
                                            ?.validated
                                        ) !== 2 && (
                                          <IdentityFileItem
                                            title={proofOfAddressTitle}
                                            openDocument={openDocument}
                                            id="proof_of_address"
                                            filePathFinal={
                                              proofOfAddressFilePathFinal
                                            }
                                            validatedType={
                                              proofOfAddressValidatedType
                                            }
                                            details={[]}
                                          />
                                        )}
                                        {/* Attestation d'hébergement */}
                                        {Number(
                                          infoMission.user_assigned_infos
                                            .tempworker
                                            ?.accommodation_of_address
                                            ?.validated
                                        ) !== 2 &&
                                          infoMission.user_assigned_infos
                                            .tempworker
                                            ?.accommodation_of_address
                                            ?.file && (
                                            <IdentityFileItem
                                              title={accommodationTitle}
                                              openDocument={openDocument}
                                              id="accommodation_of_address"
                                              filePathFinal={
                                                accommodationFilePathFinal
                                              }
                                              validatedType={
                                                accommodationValidatedType
                                              }
                                              details={[]}
                                            />
                                          )}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className={'text-sm text-gray-500'}>
                                    Vous devez attribuer un profil à cette
                                    mission.
                                  </div>
                                )}
                              </div>
                              {(infoMission?.contract_company.length > 0 ||
                                infoMission?.contract_tempworker.length >
                                  0) && (
                                <>
                                  <Divider />
                                  <div className={'flex flex-col gap-2'}>
                                    <div className={''}>Contrat en cours</div>
                                    <div
                                      className={
                                        'flex flex-col py-1 px-4 border rounded-md'
                                      }
                                    >
                                      <div
                                        className={
                                          'flex gap-1 text-sm items-center py-2.5'
                                        }
                                      >
                                        <div className={''}>
                                          <DocumentIcon
                                            wh={20}
                                            color={'#111827'}
                                          />
                                        </div>
                                        <div className={''}>Contrat BeSTT</div>
                                        <div className={'text-gray-500'}>
                                          N°
                                          {infoMission?.contract_company[0]
                                            .id_bestt ||
                                            infoMission?.contract_tempworker[0]
                                              .id_bestt}
                                        </div>
                                      </div>
                                      <Divider />
                                      <div
                                        className={
                                          'flex justify-between gap-1 text-sm items-center py-2.5'
                                        }
                                      >
                                        <div
                                          className={'flex gap-1 items-center'}
                                        >
                                          <div className={''}>
                                            <ProfilIcon
                                              wh={16}
                                              color={'#111827'}
                                            />
                                          </div>
                                          <div className={''}>
                                            Signé par l'intérimaire
                                          </div>
                                        </div>
                                        <div className={''}>
                                          <BadgeSquare
                                            type={
                                              infoMission
                                                ?.contract_tempworker[0]
                                                .status === 1 ||
                                              infoMission
                                                ?.contract_tempworker[0]
                                                .status === '1'
                                                ? '10'
                                                : '9'
                                            }
                                          />
                                        </div>
                                      </div>
                                      <Divider />
                                      <div
                                        className={
                                          'flex justify-between gap-1 text-sm items-center py-2.5'
                                        }
                                      >
                                        <div
                                          className={'flex gap-1 items-center'}
                                        >
                                          <div className={''}>
                                            <Building
                                              wh={16}
                                              color={'#111827'}
                                            />
                                          </div>
                                          <div className={''}>
                                            Signé par l'entreprise
                                          </div>
                                        </div>
                                        <div className={''}>
                                          <BadgeSquare
                                            type={
                                              infoMission?.contract_company[0]
                                                .status === 1 ||
                                              infoMission?.contract_company[0]
                                                .status === '1'
                                                ? '10'
                                                : '9'
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {infoMission?.contractHistories.filter(
                                (contract) => contract.status === 'toDelete'
                              ).length > 0 && (
                                <>
                                  <Divider />
                                  <div className={'flex flex-col gap-2'}>
                                    <div className={''}>
                                      Historique des contrats
                                    </div>
                                    <div className={'text-sm text-gray-500'}>
                                      Vous devez manuellement supprimer ces
                                      contrats sur BeSTT.
                                    </div>
                                    <div className={'flex flex-col gap-2'}>
                                      {infoMission?.contractHistories
                                        .filter(
                                          (contract) =>
                                            contract.status === 'toDelete'
                                        )
                                        .map((contract) => (
                                          <div
                                            className={
                                              'py-2.5 px-4 flex justify-between text-sm items-center'
                                            }
                                          >
                                            <div
                                              className={
                                                'flex gap-1 items-center'
                                              }
                                            >
                                              <div className={''}>
                                                <DocumentIcon
                                                  wh={20}
                                                  color={'#111827'}
                                                />
                                              </div>
                                              <div className={''}>
                                                Contrat BeSTT
                                              </div>
                                              <div className={'text-gray-500'}>
                                                N°{contract.id_bestt}
                                              </div>
                                            </div>
                                            <div
                                              className={
                                                'flex gap-4 items-center'
                                              }
                                            >
                                              <div className={''}>
                                                <BadgeSquare type={'8'} />
                                              </div>
                                              <div
                                                className={'cursor-pointer'}
                                                onClick={() =>
                                                  deletedContractHistory(
                                                    contract._id
                                                  )
                                                }
                                              >
                                                <CrossIcon
                                                  wh={24}
                                                  color={'#374151'}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className={'flex justify-start gap-2 mt-4'}>
                                {infoMission.user_assigned && (
                                  <>
                                    {!loadingContractGeneration ? (
                                      <Button
                                        size={'SM'}
                                        color={'PRIMARY'}
                                        onClick={() =>
                                          generationContractSubmit(
                                            infoMission._id
                                          )
                                        }
                                      >
                                        {infoMission.contract_tempworker[0]
                                          ?.id_bestt
                                          ? 'Re-générer'
                                          : 'Générer'}{' '}
                                        le contrat
                                      </Button>
                                    ) : (
                                      <Button size={'SM'} color={'DISABLED'}>
                                        {infoMission.contract_tempworker[0]
                                          ?.id_bestt
                                          ? 'Re-génération'
                                          : 'Génération'}{' '}
                                        du contrat ...
                                      </Button>
                                    )}
                                    {!loadingSignatureMission ? (
                                      <>
                                        <Button
                                          size={'SM'}
                                          color={
                                            infoMission.contract_tempworker[0]
                                              ?.id_bestt
                                              ? 'PRIMARY'
                                              : 'DISABLED'
                                          }
                                          onClick={
                                            infoMission.contract_tempworker[0]
                                              ?.id_bestt
                                              ? () =>
                                                  sendSignatureMissionSubmit(
                                                    infoMission._id
                                                  )
                                              : null
                                          }
                                        >
                                          {infoMission.contract_tempworker[0]
                                            ?.yousign_id
                                            ? 'Renvoyer'
                                            : 'Envoyer'}{' '}
                                          pour signature
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          size={'SM'}
                                          color={'DISABLED'}
                                          disabled
                                        >
                                          Envoi du contrat pour signature ...
                                        </Button>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                          {missionPage === 3 && (
                            <>
                              <div
                                className={
                                  'flex flex-col overflow-y-auto h-full'
                                }
                              >
                                <div
                                  className={
                                    'flex flex-1 flex-col gap-6 text-sm'
                                  }
                                >
                                  {(role === 500 || role === 515) && (
                                    <>
                                      <div
                                        className={
                                          'flex gap-2 justify-between items-center'
                                        }
                                      >
                                        <div className={''}>
                                          Modifier le détail de la mission
                                        </div>
                                        {!modifyMissionDetail && (
                                          <div className={''}>
                                            <Button
                                              size={'SM'}
                                              color={'SECONDARY'}
                                              onClick={() =>
                                                setModifyMissionDetail(true)
                                              }
                                            >
                                              <div className={'flex gap-1'}>
                                                <div>
                                                  <Pencil
                                                    wh={20}
                                                    color={'#374151'}
                                                  />
                                                </div>
                                                Modifier
                                              </div>
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                      <Divider />
                                    </>
                                  )}
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Entreprise
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {infoMission.company?.name_commercial}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Qualification
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <div className={''}>
                                          <StyledInput
                                            type={'selectMultipleRadio'}
                                            value={
                                              qualifications.filter(
                                                (qualification) =>
                                                  qualification.checked === true
                                              ).length > 0
                                                ? qualifications
                                                    .filter(
                                                      (qualification) =>
                                                        qualification.checked ===
                                                        true
                                                    )
                                                    .map(
                                                      (qualification) =>
                                                        qualification.name
                                                    )
                                                    .join(', ')
                                                : 'Qualification'
                                            }
                                            list={qualifications.map(
                                              (qualification) => {
                                                return {
                                                  value: qualification._id,
                                                  label: qualification.name,
                                                  checked:
                                                    qualification.checked,
                                                };
                                              }
                                            )}
                                            onChange={(updatedQualification) =>
                                              updateQualification(
                                                updatedQualification
                                              )
                                            }
                                            isSearchable
                                          />
                                        </div>
                                      ) : (
                                        infoMission.title
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Période
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <div className={'flex gap-2 text-sm'}>
                                          <div className={'w-full'}>
                                            <div>
                                              <label className="text-gray-500 flex flex-col gap-1">
                                                <div>Date de début</div>
                                                <DatePicker
                                                  dateFormat="dd/MM/yyyy"
                                                  selected={
                                                    modifyMissionDetailData
                                                      .period.start
                                                  }
                                                  onChange={(date) =>
                                                    setModifyMissionDetailData(
                                                      (prevData) => ({
                                                        ...prevData,
                                                        period: {
                                                          ...prevData.period,
                                                          start: date,
                                                        },
                                                      })
                                                    )
                                                  }
                                                  locale="fr-FR"
                                                  peekNextMonth
                                                  showMonthDropdown
                                                  showYearDropdown
                                                  dropdownMode="select"
                                                  popperPlacement="bottom"
                                                  className="text-sm w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center"
                                                />
                                              </label>
                                            </div>
                                          </div>
                                          <div className={'w-full'}>
                                            <div>
                                              <label className="text-gray-500 flex flex-col gap-1">
                                                <div>Date de fin</div>
                                                <DatePicker
                                                  dateFormat="dd/MM/yyyy"
                                                  selected={
                                                    modifyMissionDetailData
                                                      .period.end
                                                  }
                                                  onChange={(date) =>
                                                    setModifyMissionDetailData(
                                                      (prevData) => ({
                                                        ...prevData,
                                                        period: {
                                                          ...prevData.period,
                                                          end: date,
                                                        },
                                                      })
                                                    )
                                                  }
                                                  locale="fr-FR"
                                                  peekNextMonth
                                                  showMonthDropdown
                                                  showYearDropdown
                                                  dropdownMode="select"
                                                  popperPlacement="bottom"
                                                  className="text-sm w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center"
                                                />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className={'flex gap-1'}>
                                          <div className={''}>
                                            {dateWordShort(
                                              infoMission.duration?.datestart
                                            )}
                                          </div>
                                          <div className={''}>{'>'}</div>
                                          <div className={''}>
                                            {dateWordShort(
                                              infoMission.duration?.dateend
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {modifyMissionDetail ? (
                                    <Schedule
                                      mission={infoMission}
                                      dateStart={
                                        modifyMissionDetailData.period.start
                                      }
                                      dateEnd={
                                        modifyMissionDetailData.period.end
                                      }
                                      schedule={
                                        modifyMissionDetailData.schedule
                                      }
                                      isEditable
                                    />
                                  ) : (
                                    <Schedule
                                      mission={infoMission}
                                      dateStart={infoMission.duration.datestart}
                                      dateEnd={infoMission.duration.dateend}
                                      schedule={infoMission.schedules}
                                    />
                                  )}
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Rémunération
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <Salary
                                          salary={
                                            modifyMissionDetailData.salary
                                          }
                                          onChange={(value) => {
                                            setModifyMissionDetailData(
                                              (prevData) => ({
                                                ...prevData,
                                                salary: value,
                                              })
                                            );
                                          }}
                                        />
                                      ) : (
                                        infoMission.salary.wage_per_hour + '€/h'
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Adresse
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <AddressGeoApi
                                          label={'Adresse'}
                                          addressGeoValue={
                                            setAddressMissionDetail
                                          }
                                          addressValue={addressMissionDetail}
                                          isAddressCompany={
                                            infoMission.company.location
                                          }
                                          page={'adminMissionDetail'}
                                        />
                                      ) : (
                                        infoMission.address.street +
                                        ' ' +
                                        infoMission.address.postcode +
                                        ' ' +
                                        infoMission.address.city +
                                        ', ' +
                                        infoMission.address.department
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Motif
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <div
                                          className={
                                            'border rounded-lg px-2 py-3 flex justify-between text-sm text-gray-700'
                                          }
                                        >
                                          <div
                                            className={'flex flex-col gap-1'}
                                          >
                                            <div className={'font-bold'}>
                                              {
                                                modifyMissionDetailData.reason
                                                  .reason
                                              }
                                            </div>
                                            <div className={''}>
                                              {(modifyMissionDetailData.reason
                                                .proof_firstname ||
                                                modifyMissionDetailData.reason
                                                  .proof_lastname) &&
                                                modifyMissionDetailData.reason
                                                  .proof_firstname +
                                                  ' ' +
                                                  modifyMissionDetailData.reason
                                                    .proof_lastname +
                                                  ' - '}{' '}
                                              {
                                                modifyMissionDetailData.reason
                                                  .proof
                                              }
                                            </div>
                                          </div>
                                          <div className={''}>
                                            <Button
                                              size={'SMI'}
                                              color={'SECONDARY'}
                                              onClick={() =>
                                                editMissionDetailReason()
                                              }
                                            >
                                              <Pencil
                                                wh={20}
                                                color={'#374151'}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {infoMission.reason_detail.reason} :{' '}
                                          {(infoMission.reason_detail
                                            .proof_firstname ||
                                            infoMission.reason_detail
                                              .proof_lastname) &&
                                            infoMission.reason_detail
                                              .proof_firstname +
                                              ' ' +
                                              infoMission.reason_detail
                                                .proof_lastname +
                                              ' - '}{' '}
                                          {infoMission.reason_detail.proof}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Permis
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <StyledInput
                                          type={'selectMultipleCheckbox'}
                                          isSearchable
                                          value={
                                            licences.filter(
                                              (licence) =>
                                                licence.checked === true
                                            ).length > 0
                                              ? licences
                                                  .filter(
                                                    (licence) =>
                                                      licence.checked === true
                                                  )
                                                  .map(
                                                    (licence) => licence.name
                                                  )
                                                  .join(', ')
                                              : '-'
                                          }
                                          list={licences.map((licence) => {
                                            return {
                                              value: licence.name,
                                              label: licence.name,
                                              checked: licence.checked,
                                            };
                                          })}
                                          onChange={(updatedLicence) =>
                                            updateLicence(updatedLicence)
                                          }
                                        />
                                      ) : infoMission.licences.length > 0 ? (
                                        infoMission.licences.join(', ')
                                      ) : (
                                        'Aucun'
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Matériel
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <StyledInput
                                          type={'selectMultipleCheckbox'}
                                          isSearchable
                                          value={
                                            tools.filter(
                                              (tool) => tool.checked === true
                                            ).length > 0
                                              ? tools
                                                  .filter(
                                                    (tool) =>
                                                      tool.checked === true
                                                  )
                                                  .map((tool) => tool.name)
                                                  .join(', ')
                                              : '-'
                                          }
                                          list={tools.map((tool) => {
                                            return {
                                              value: tool.name,
                                              label: tool.name,
                                              checked: tool.checked,
                                            };
                                          })}
                                          onChange={(updatedTool) =>
                                            updateTool(updatedTool)
                                          }
                                        />
                                      ) : infoMission.tools.length > 0 ? (
                                        infoMission.tools.join(', ')
                                      ) : (
                                        'Aucun'
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Langue
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <StyledInput
                                          type={'selectMultipleCheckbox'}
                                          isSearchable
                                          value={
                                            lang.filter(
                                              (lang) => lang.checked === true
                                            ).length > 0
                                              ? lang
                                                  .filter(
                                                    (lang) =>
                                                      lang.checked === true
                                                  )
                                                  .map((lang) => lang.name)
                                                  .join(', ')
                                              : '-'
                                          }
                                          list={lang.map((lang) => {
                                            return {
                                              value: lang.name,
                                              label: lang.name,
                                              checked: lang.checked,
                                            };
                                          })}
                                          onChange={(updatedLanguage) =>
                                            updateLanguage(updatedLanguage)
                                          }
                                        />
                                      ) : infoMission.language.length > 0 ? (
                                        infoMission.language.join(', ')
                                      ) : (
                                        'Aucune'
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Référence
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <StyledInput
                                          type={'text'}
                                          value={
                                            modifyMissionDetailData.reference
                                          }
                                          onChange={(e) => {
                                            setModifyMissionDetailData(
                                              (prevData) => ({
                                                ...prevData,
                                                reference: e.target.value,
                                              })
                                            );
                                          }}
                                        />
                                      ) : (
                                        infoMission.reference || 'Aucune'
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Informations pour le candidat
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <StyledInput
                                          type={'textarea'}
                                          value={modifyMissionDetailData.other}
                                          onChange={(e) => {
                                            setModifyMissionDetailData(
                                              (prevData) => ({
                                                ...prevData,
                                                other: e.target.value,
                                              })
                                            );
                                          }}
                                        />
                                      ) : (
                                        infoMission.other || 'Aucune'
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className={'flex gap-1'}>
                                    <div className={'w-1/2 font-medium'}>
                                      Informations pour l'agence
                                    </div>
                                    <div className={'w-1/2 text-gray-700'}>
                                      {modifyMissionDetail ? (
                                        <StyledInput
                                          type={'textarea'}
                                          value={
                                            modifyMissionDetailData.other_interim
                                          }
                                          onChange={(e) => {
                                            setModifyMissionDetailData(
                                              (prevData) => ({
                                                ...prevData,
                                                other_interim: e.target.value,
                                              })
                                            );
                                          }}
                                        />
                                      ) : (
                                        infoMission.other_interim || 'Aucune'
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                </div>
                              </div>
                              {modifyMissionDetail && (
                                <div
                                  className={
                                    'flex items-center justify-end px-6 py-0 rounded-b gap-2'
                                  }
                                >
                                  <Button
                                    size={'SM'}
                                    color={'SECONDARY'}
                                    onClick={() =>
                                      setModifyMissionDetail(false)
                                    }
                                  >
                                    Annuler
                                  </Button>
                                  {isChangesModifyMissionDetail ? (
                                    <Button
                                      size={'SM'}
                                      color={'PRIMARY'}
                                      onClick={() =>
                                        submitModifyMissionDetail()
                                      }
                                    >
                                      Confirmer
                                    </Button>
                                  ) : (
                                    <Button size={'SM'} color={'DISABLED'}>
                                      Confirmer
                                    </Button>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>Pas de données</>
          )}
        </>
      )}
    </>
  );
};

export default AdminMissionDetails;
