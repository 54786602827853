import Pagination from 'components/pagination/pagination';
import UseContext from 'hooks/useContext';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiDatabase from 'server';
import Divider from '../../../../divider/divider';
import EmptyState from '../../../../EmptyState/EmptyState';
import ModalBody from '../../../components/body';
import Modalheader from '../../../components/header';
import useModal from '../../../useModal';

const ActivityVivier = () => {
  const { toggle } = useModal();
  const { modalData } = UseContext();
  const [logViviers, setLogViviers] = useState({});
  const [countTotal, setCountTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitElement, setLimitElement] = useState(5);
  const [countPerPage, setCountPerPage] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);

  const token = localStorage.getItem('xsrfToken');

  useEffect(() => {
    if (modalData.idVivier && token) {
      setIsWaiting(true);
      ApiDatabase.getLogVivier(
        {
          token,
          idVivier: modalData.idVivier,
          limit: limitElement,
          page: currentPage,
        },
        (data) => {
          setLogViviers(data.logVivier);
          setTotalPages(data.countPages);
          setCountPerPage(data.countPerPage);
          setCountTotal(data.count);
          setIsWaiting(false);
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.error(err);
            toast.error('Une erreur est survenue');
            setIsWaiting(false);
          }
        }
      );
    }
  }, [modalData.idVivier, currentPage, limitElement, token]);

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Activité du vivier
      </Modalheader>
      <ModalBody>
        {isWaiting ? (
          <div className={'animate-pulse'}>
            {[1, 2, 3, 4, 5].map((i, index) => (
              <div key={index}>
                <div className="flex flex-col gap-2 py-1">
                  <div className={'font-bold'}>
                    <div className="w-56 h-6 bg-gray-200 rounded"></div>
                  </div>
                  <div className={'text-gray-500'}>
                    <div className="w-32 h-6 bg-gray-200 rounded"></div>
                  </div>
                </div>
                <Divider />
              </div>
            ))}
          </div>
        ) : (
          <>
            {Object.keys(logViviers).length > 0 ? (
              <>
                <div className="flex flex-col gap-4 py-3">
                  {logViviers.map((log, key) => {
                    //transform date log.createdAt to dd/mm/yyyy, hh:ii
                    const dateLogVivier = new Date(log.createdAt);
                    const dateLogVivierFormat = `${(
                      '0' + dateLogVivier.getDate()
                    )
                      .toString()
                      .slice(-2)}/${('0' + (dateLogVivier.getMonth() + 1))
                      .toString()
                      .slice(-2)}/${dateLogVivier.getFullYear()}, ${(
                      '0' + dateLogVivier.getHours()
                    )
                      .toString()
                      .slice(-2)}:${('0' + dateLogVivier.getMinutes())
                      .toString()
                      .slice(-2)}`;
                    return (
                      <>
                        <div className="flex flex-col gap-1" key={key}>
                          <div className={'font-bold'}>
                            Notifié le {dateLogVivierFormat}
                          </div>
                          <div className={'text-gray-500'}>
                            par {log.employee?.firstname}{' '}
                            {log.employee?.lastname}
                          </div>
                        </div>
                        <Divider />
                      </>
                    );
                  })}
                </div>
                <div className={''}>
                  <Pagination
                    currentPage={currentPage}
                    countPerPage={countPerPage}
                    countTotal={countTotal}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </>
            ) : (
              <EmptyState type={'log_vivier'} />
            )}
          </>
        )}
      </ModalBody>
    </>
  );
};

export default ActivityVivier;
