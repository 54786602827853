import {
  ArrowRight,
  Calendar,
  Check,
  CommentIcon,
  CrossIcon,
  DocumentIcon,
  NegativeThumb,
  PositiveThumb,
  Star,
  Warning,
} from '../../../assets/icons';
import Badge from '../../../components/badge/badge';
import Button from '../../../components/button';
import UseModal from '../../../components/modal/useModal';
import useSlideOver from '../../../components/SlideOver/useSlideOver';
import TempWorkerMission from '../../../components/tempWorkerMission/tempWorkerMission';
import Tooltip from '../../../components/tooltips/Tooltips';
import { dateToEpoch, dateWordMonthShort } from '../../../helper/helper';
import UseContext from '../../../hooks/useContext';

const MissionDetailTempWorker = ({
  tempWorker,
  index,
  missionDateStart,
  missionDateEnd,
  onClickAssignation,
  onClickRefused,
  isRefused,
}) => {
  const {
    setObjID,
    setAdminLink,
    setAdminType,
    setAdminTempWorkerId,
    setTarget,
  } = UseContext();
  const { toggleSlideOver } = useSlideOver();
  const { toggle } = UseModal();

  const openTempWorkerInfo = (id, target = '') => {
    setTarget(target);
    setObjID(id);
    toggleSlideOver('get_temp_worker');
  };

  const openDocument = (type, uri) => {
    setAdminLink(uri);
    setAdminType(type);
    setAdminTempWorkerId('');
    toggle('ad_file_view');
  };

  const kanbanColumns = [
    { name: 'wish', title: 'Souhait', icon: '🧞' },
    { name: 'test', title: 'Test', icon: '⏳' },
    { name: 'valid', title: 'Validé', icon: '✅' },
    { name: 'refused', title: 'Refusé', icon: '❌' },
    { name: 'archive', title: 'Archivé', icon: '📦' },
  ];

  if (tempWorker.id_user) {
    tempWorker._id = tempWorker.id_user;
  }

  return (
    <div
      key={index}
      className={'w-full flex flex-col gap-5 px-2 py-4 border-b cursor-pointer'}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        openTempWorkerInfo(tempWorker._id);
      }}
    >
      <div className={'flex flex-col gap-2'}>
        <div className={'text-gray-500'}>
          <div className={'flex gap-1 items-center'}>
            <div className={'w-full text-sm'}>
              <TempWorkerMission user={tempWorker} />
            </div>
            <div className={'flex gap-1'}>
              {onClickRefused && (
                <div className={'w-fit'}>
                  <Button
                    size={'SMI'}
                    color={isRefused ? 'REFUSED' : 'DEFAULT'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClickRefused(tempWorker._id);
                    }}
                  >
                    <CrossIcon
                      wh={20}
                      color={isRefused ? '#DC2626' : '#374151'}
                    />
                  </Button>
                </div>
              )}
              {onClickAssignation && (
                <div className={'w-fit'}>
                  <Button
                    size={'SMI'}
                    color={'DEFAULT'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClickAssignation(tempWorker._id);
                    }}
                  >
                    <Check wh={20} color={'#374151'} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {tempWorker.vivierExclude === false && (
          <div className={'flex justify-between w-full text-sm'}>
            {tempWorker.availablePeriodMission?.dateStart ||
            tempWorker.availablePeriodMission?.dateEnd ? (
              <>
                {dateToEpoch(
                  tempWorker.availablePeriodMission?.dateStart
                ).getTime() === dateToEpoch(missionDateStart).getTime() &&
                dateToEpoch(
                  tempWorker.availablePeriodMission?.dateEnd
                ).getTime() === dateToEpoch(missionDateEnd).getTime() ? (
                  <div className={'flex gap-1'}>
                    <div className={'my-auto'}>
                      <Calendar wh={16} color={'#6B7280'} />
                    </div>
                    <div className={'my-auto text-gray-500'}>
                      Toute la période
                    </div>
                  </div>
                ) : (
                  <>
                    {dateToEpoch(
                      tempWorker.availablePeriodMission?.dateStart
                    ).getTime() === dateToEpoch(missionDateStart).getTime() &&
                      dateToEpoch(
                        tempWorker.availablePeriodMission?.dateEnd
                      ).getTime() !== dateToEpoch(missionDateEnd).getTime() && (
                        <>
                          <div className={'flex gap-1 text-gray-500'}>
                            <div className={'my-auto'}>
                              <Warning wh={20} color={'#FF9600'} />
                            </div>
                            <div className={'my-auto'}>
                              {dateWordMonthShort(
                                dateToEpoch(missionDateStart)
                              )}
                            </div>
                            <div className={'my-auto'}>
                              <ArrowRight wh={20} color={'#6B7280'} />
                            </div>
                            <div className={'my-auto'}>
                              {dateWordMonthShort(
                                dateToEpoch(
                                  tempWorker.availablePeriodMission?.dateEnd
                                )
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    {dateToEpoch(
                      tempWorker.availablePeriodMission?.dateStart
                    ).getTime() !== dateToEpoch(missionDateStart).getTime() &&
                      dateToEpoch(
                        tempWorker.availablePeriodMission?.dateEnd
                      ).getTime() === dateToEpoch(missionDateEnd).getTime() && (
                        <>
                          <div className={'flex gap-1 text-gray-500'}>
                            <div className={'my-auto'}>
                              <Warning wh={20} color={'#FF9600'} />
                            </div>
                            <div className={'my-auto'}>
                              {dateWordMonthShort(
                                dateToEpoch(
                                  tempWorker.availablePeriodMission?.dateStart
                                )
                              )}
                            </div>
                            <div className={'my-auto'}>
                              <ArrowRight wh={20} color={'#6B7280'} />
                            </div>
                            <div className={'my-auto'}>
                              {dateWordMonthShort(dateToEpoch(missionDateEnd))}
                            </div>
                          </div>
                        </>
                      )}
                    {dateToEpoch(
                      tempWorker.availablePeriodMission?.dateStart
                    ).getTime() !== dateToEpoch(missionDateStart).getTime() &&
                      dateToEpoch(
                        tempWorker.availablePeriodMission?.dateEnd
                      ).getTime() !== dateToEpoch(missionDateEnd).getTime() && (
                        <>
                          <div className={'flex gap-1 text-gray-500'}>
                            <div className={'my-auto'}>
                              <Warning wh={20} color={'#FF9600'} />
                            </div>
                            <div className={'my-auto'}>
                              {dateWordMonthShort(
                                dateToEpoch(
                                  tempWorker.availablePeriodMission?.dateStart
                                )
                              )}
                            </div>
                            <div className={'my-auto'}>
                              <ArrowRight wh={20} color={'#6B7280'} />
                            </div>
                            <div className={'my-auto'}>
                              {dateWordMonthShort(
                                dateToEpoch(
                                  tempWorker.availablePeriodMission?.dateEnd
                                )
                              )}
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </>
            ) : (
              <div className={'flex gap-1'}>
                <div className={'my-auto'}>
                  <Warning wh={20} color={'#EF4444'} />
                </div>
                <div className={'my-auto text-gray-500'}>Non disponible</div>
              </div>
            )}
            {tempWorker.weekDaysStatus?.length > 0 && (
              <div className={'flex gap-1 text-base'}>
                {tempWorker.weekDaysStatus.map((dayStatus, index) => {
                  const days = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
                  return (
                    <Tooltip
                      key={index}
                      text={
                        dayStatus === null
                          ? `Jour non travaillé mais intérimaire disponible.`
                          : dayStatus === 'not_worked_and_unavailable_day'
                          ? `Jour non travaillé et intérimaire indisponible.`
                          : dayStatus === true
                          ? `Jour travaillé et intérimaire disponible.`
                          : `Jour travaillé mais intérimaire non disponible.`
                      }
                      placement="top"
                    >
                      <div
                        className={`flex justify-center items-center my-auto rounded w-[24px] h-[24px] cursor-pointer ${
                          dayStatus === null
                            ? 'text-gray-500 bg-gray-100'
                            : dayStatus === 'not_worked_and_unavailable_day'
                            ? 'text-yellow-500 bg-yellow-50'
                            : dayStatus === true
                            ? 'text-green-500 bg-green-50'
                            : 'text-red-500 bg-red-50'
                        }`}
                      >
                        {days[index]}
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={'flex justify-between items-center'}>
        {tempWorker.vivierExclude === false ? (
          <div className={'flex flex-col justify-between'}>
            <div className={'flex flex-col gap-2'}>
              <div className={'flex gap-1'}>
                {tempWorker.vivierMission && (
                  <div
                    className={'border rounded text-xs py-0.5 px-2 flex gap-1'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openTempWorkerInfo(tempWorker._id, 'vivier');
                    }}
                  >
                    <div>
                      {
                        kanbanColumns.find(
                          (column) =>
                            column.name === tempWorker.vivierMission.status
                        )?.icon
                      }
                    </div>
                    <div>{tempWorker.vivierMission.name}</div>
                  </div>
                )}
                {tempWorker.review?.type && (
                  <>
                    {tempWorker.review?.type === 'positive' && (
                      <div
                        className={'my-auto w-fit rounded-full p-1 bg-green-50'}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openTempWorkerInfo(tempWorker._id, 'review');
                        }}
                      >
                        <PositiveThumb wh={16} color={'#10B981'} />
                      </div>
                    )}
                    {tempWorker.review?.type === 'negative' && (
                      <div
                        className={'my-auto w-fit rounded-full p-1 bg-red-50'}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openTempWorkerInfo(tempWorker._id, 'review');
                        }}
                      >
                        <NegativeThumb wh={16} color={'#EF4444'} />
                      </div>
                    )}
                  </>
                )}
                {tempWorker.rating !== null && (
                  <div
                    className={
                      'my-auto border rounded-full flex items-center gap-1 pl-1.5 pr-2 text-sm'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openTempWorkerInfo(tempWorker._id, 'review');
                    }}
                  >
                    <div className={''}>
                      <Star wh={16} color={'#FF9600'} />
                    </div>
                    <div className={''}>{tempWorker.rating}</div>
                  </div>
                )}
                {tempWorker.commentCount > 0 && (
                  <div
                    className={
                      'my-auto border rounded-full flex items-center gap-1 pl-1.5 pr-2 text-sm'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openTempWorkerInfo(tempWorker._id, 'comment');
                    }}
                  >
                    <div className={''}>
                      <CommentIcon wh={16} color={'#374151'} />
                    </div>
                    <div className={''}>{tempWorker.commentCount}</div>
                  </div>
                )}
                {tempWorker.cv?.file?.filePath && (
                  <div
                    className={
                      'my-auto border rounded-full flex items-center gap-1 pl-1.5 pr-2 text-sm'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openDocument('cv', {
                        filePath:
                          process.env.REACT_APP_FILE +
                          tempWorker.cv.file.filePath,
                      });
                    }}
                  >
                    <div className={''}>
                      <DocumentIcon wh={16} color={'#374151'} />
                    </div>
                    <div className={''}>CV</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={'flex flex-col'}>
            <div className={'my-auto'}>
              <Badge type={'PRIMARY'}>Exclu</Badge>
            </div>
          </div>
        )}
        <div className={'w-fit'}>
          {(tempWorker.identity?.validated === '2' ||
            tempWorker.identity?.validated === 2) &&
          (tempWorker.card_vitality?.validated === '2' ||
            tempWorker.card_vitality?.validated === 2) &&
          (tempWorker.proof_of_address?.validated === '2' ||
            tempWorker.proof_of_address?.validated === 2) ? (
            <Badge type={'VALID'}>Complet</Badge>
          ) : (
            <Badge type={'ALERT'}>Incomplet</Badge>
          )}
        </div>
      </div>
    </div>
  );
};

export default MissionDetailTempWorker;
