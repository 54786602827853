import { createContext, useEffect, useRef, useState } from 'react';

import { LangEn } from 'assets/locales/en';
import { Language as LanguageManager } from 'assets/locales/fLang';
import { LangFr } from 'assets/locales/fr';
import { useLocation } from 'react-router-dom';
import ApiDatabase from '../server';

const roadContext = createContext({});

function ContextProvider({ children }) {
  // *******   Section Sidebar   *******
  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );
  // *******   Section Refresh   *******
  const [refreshUser, setRefreshUser] = useState(false);
  const refreshLoadUser = () => {
    setRefreshUser(!refreshUser);
  };
  const [refreshWorker, setRefreshWorker] = useState(false);
  const refreshLoadWorker = () => {
    setRefreshWorker(!refreshWorker);
  };
  // *******   Section Language   *******
  //    instancie le manager
  const language = new LanguageManager();
  //    charge les fichiers de langues (ils seront chargés qu'une fois)
  language.loadLanguage(LangFr);
  language.loadLanguage(LangEn);
  //    spécifie la langue en cours
  language.setLanguage('fr');

  // *******   Section Modal   *******
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState({});
  const [modalDataReturn, setModalDataReturn] = useState({});

  // *******   Section URL   *******
  const [slideOverShow, setSlideOverShow] = useState(false);
  const [slideOverType, setSlideOverType] = useState('');

  // *******   Section URL   *******
  const route = useLocation();
  const { pathname } = route;
  //1 - interimaire 2- entreprise
  const [typeNav, setTypeNav] = useState(1);
  const [email, setEmail] = useState('');
  const [name, setName] = useState({
    firstname: '',
    lastname: '',
  });
  useEffect(() => {
    if (pathname.includes('entreprise')) setTypeNav(2);
    else setTypeNav(1);
  }, [pathname]);

  const [auth, setAuth] = useState({});
  const updateUser = (pToken = '-1', pRole) => {
    if (pToken !== '-1') {
      localStorage.setItem('xsrfToken', pToken);
      localStorage.setItem('rUser', pRole);
    }
  };

  // *******   Section Time Sheets   *******
  const [timeData, setTimeData] = useState({
    id: '',
    id_time_sheet: '',
    index: '',
    day_hours_ent: '',
    periode_one_start: '',
    periode_one_end: '',
    periode_two_start: '',
    periode_two_end: '',
    periode_three_start: '',
    periode_three_end: '',
  });
  const [totalH, setTotalH] = useState(0);
  const [refreshTime, setRefreshTime] = useState(false);
  const [timeCheck, setTimeCheck] = useState({});
  const [content, setContent] = useState('');
  // *******   Section OBJ   *******
  const [expID, setExpID] = useState('');
  const [objID, setObjID] = useState('');
  const [objType, setObjType] = useState('');
  const [adminLink, setAdminLink] = useState('');
  const [adminTempWorkerId, setAdminTempWorkerId] = useState('');
  const [adminType, setAdminType] = useState('');
  const [updateTempWorker, setUpdateTempWorker] = useState(false);

  const [coeffs, setCoeffs] = useState([]);

  // #region **** Vivier ****
  const [infoVivier, setInfoVivier] = useState();
  const [listVivier, setListVivier] = useState();
  const [refreshVivierLog, setRefreshVivierLog] = useState(true);
  // #endregion **** Vivier ****

  const [userUnAssigned, setUserUnAssigned] = useState({});

  const [currentPageTimeSheetToBeChecked, setCurrentPageTimeSheetToBeChecked] =
    useState(1);
  const [
    timeSheetSearchTimeSheetToBeChecked,
    setTimeSheetSearchTimeSheetToBeChecked,
  ] = useState('');
  const [
    timeSheetSearchTypeTimeSheetToBeChecked,
    setTimeSheetSearchTypeTimeSheetToBeChecked,
  ] = useState('');
  const [dateRangeTimeSheetToBeChecked, setDateRangeTimeSheetToBeChecked] =
    useState([null, null]);

  const [currentPageTimeSheetChecked, setCurrentPageTimeSheetChecked] =
    useState(1);
  const [timeSheetSearchTimeSheetChecked, setTimeSheetSearchTimeSheetChecked] =
    useState('');
  const [
    timeSheetSearchTypeTimeSheetChecked,
    setTimeSheetSearchTypeTimeSheetChecked,
  ] = useState('');
  const [dateRangeTimeSheetChecked, setDateRangeTimeSheetChecked] = useState([
    null,
    null,
  ]);

  const [currentPageTimeSheetLitigation, setCurrentPageTimeSheetLitigation] =
    useState(1);
  const [
    timeSheetSearchTimeSheetLitigation,
    setTimeSheetSearchTimeSheetLitigation,
  ] = useState('');
  const [
    timeSheetSearchTypeTimeSheetLitigation,
    setTimeSheetSearchTypeTimeSheetLitigation,
  ] = useState('');
  const [dateRangeTimeSheetLitigation, setDateRangeTimeSheetLitigation] =
    useState([null, null]);

  const [openModal, setOpenModal] = useState(false);

  const [missionDetails, setMissionDetails] = useState({});
  const [scheduleWeeks, setScheduleWeeks] = useState([]);
  const [updSchedule, setUpdSchedule] = useState(false);
  const [activeScheduleWeekDay, setActiveScheduleWeekDay] = useState([]);
  const [scheduleWeeksNew, setScheduleWeeksNew] = useState([]);
  const [refreshMissionDetails, setRefreshMissionDetails] = useState({});

  const [refreshCompanyBigAccount, setRefreshCompanyBigAccount] =
    useState(false);

  const [reviewUntreatedDelete, setReviewUntreatedDelete] = useState(false);
  const [refreshUntreated, setRefreshUntreated] = useState(false);

  const [refreshQualifications, setRefreshQualifications] = useState(false);
  const [refreshQualification, setRefreshQualification] = useState(false);

  const [usersVivier, setUsersVivier] = useState([]);

  const [userVivierToArchive, setUserVivierToArchive] = useState({});
  const [userVivierToArchiveUpdated, setUserVivierToArchiveUpdated] = useState(
    {}
  );

  const [commentID, setCommentID] = useState('');
  const [commentIDUpd, setCommentIDUpd] = useState('');

  const [userData, setUserData] = useState({});
  const [tempWorkerData, setTempWorkerData] = useState({});

  const [target, setTarget] = useState('');

  const [assignationMissionTempWorker, setAssignationMissionTempWorker] =
    useState({});

  const [modifyMissionDetailData, setModifyMissionDetailData] = useState({});

  const [listChangesMissionDetail, setListChangesMissionDetail] = useState({});
  const [
    isChangesNeedsNewContractMissionDetail,
    setIsChangesNeedsNewContractMissionDetail,
  ] = useState({});

  const [searchMissionResult, setSearchMissionResult] = useState([]);

  const [searchTempWorkerResult, setSearchTempWorkerResult] = useState(false);

  const [
    searchTempWorkerAssignationResult,
    setSearchTempWorkerAssignationResult,
  ] = useState(false);
  const [
    searchTempWorkerAssignationRefusedResult,
    setSearchTempWorkerAssignationRefusedResult,
  ] = useState(false);

  const [searchTempWorkerInviteResult, setSearchTempWorkerInviteResult] =
    useState([]);

  const [infoMission, setInfoMission] = useState(false);

  const infoMissionRef = useRef(infoMission);
  const searchTempWorkerResultRef = useRef(searchTempWorkerResult);

  useEffect(() => {
    infoMissionRef.current = infoMission;
  }, [infoMission]);

  useEffect(() => {
    searchTempWorkerResultRef.current = searchTempWorkerResult;
  }, [searchTempWorkerResult]);

  const socketRef = useRef(null);
  const token = localStorage.getItem('xsrfToken');

  useEffect(() => {
    const manageSocket = async () => {
      socketRef.current = await ApiDatabase.connectSocketWithToken();
      if (socketRef.current) {
        const handleSearchMissionUpdated = (change, eventName) => {
          const url = 'admin/searchMission';
          const data = {};
          ApiDatabase.updateCacheEntry(url, data, change.searchMission);

          if (change.searchMission?.length > 0) {
            setSearchMissionResult((prevData) =>
              prevData.map((mission) => {
                const updatedMission = change.searchMission.find(
                  (searchMission) => searchMission._id === mission._id
                );
                return updatedMission ? updatedMission : mission;
              })
            );
          }
        };

        const handleSearchMissionInserted = (change, eventName) => {
          const url = 'admin/searchMission';
          const data = {};
          ApiDatabase.updateCacheEntry(url, data, change.searchMission);

          if (change.searchMission?.length > 0) {
            setSearchMissionResult((prevData) => {
              const updatedData = [...prevData];

              change.searchMission.forEach((newMission) => {
                const existingMission = updatedData.find(
                  (mission) => mission._id === newMission._id
                );

                if (existingMission) {
                  Object.assign(existingMission, newMission);
                } else {
                  updatedData.push(newMission);
                }
              });

              return updatedData;
            });
          }
        };

        const handleTempWorkerPostulateMissionUpdated = (change, eventName) => {
          if (
            change.idMission &&
            change.tempWorkerPostulateMission &&
            infoMissionRef.current?._id === change.idMission
          ) {
            const url = 'admin/getTempWorkerPostulateMission';
            const data = { token, idMission: change.idMission };
            ApiDatabase.updateCacheEntry(
              url,
              data,
              change.tempWorkerPostulateMission
            );

            let refusedUsers = {
              complete: [],
              partial: [],
              not_updated: [],
              incomplete: [],
            };

            let otherUsers = {
              complete: [],
              partial: [],
              not_updated: [],
              incomplete: [],
            };

            const categories = [
              'complete',
              'partial',
              'not_updated',
              'incomplete',
            ];

            categories.forEach((category) => {
              if (change.tempWorkerPostulateMission[category]) {
                refusedUsers[category] = change.tempWorkerPostulateMission[
                  category
                ].filter((user) => user.refused === true);
                otherUsers[category] = change.tempWorkerPostulateMission[
                  category
                ].filter((user) => user.refused === false);
              } else {
                refusedUsers[category] = [];
                otherUsers[category] = [];
              }
            });

            setSearchTempWorkerAssignationRefusedResult(refusedUsers);
            setSearchTempWorkerAssignationResult(otherUsers);
          }
        };

        const handleTempWorkerInviteMissionUpdated = (change, eventName) => {
          if (
            change.idMission &&
            change.tempWorkerInviteMission &&
            infoMissionRef.current?._id === change.idMission
          ) {
            const url = 'admin/getTempWorkerInviteMission';
            const data = { token, idMission: change.idMission };
            ApiDatabase.updateCacheEntry(
              url,
              data,
              change.tempWorkerInviteMission
            );
            setSearchTempWorkerInviteResult(change.tempWorkerInviteMission);
          }
        };

        const handleMissionDetailsByIdUpdated = (change, eventName) => {
          if (
            change.idMission &&
            change.missionDetailsById &&
            infoMissionRef.current?._id === change.idMission
          ) {
            const url = 'admin/getMissionDetailsById';
            const data = { token, idMission: change.idMission };
            ApiDatabase.updateCacheEntry(url, data, change.missionDetailsById);
            setInfoMission(change.missionDetailsById);
          }
        };

        const handleSearchTempWorkerMissionUpdated = (change, eventName) => {
          if (
            change.idMission &&
            change.idUser &&
            change.searchTempWorker &&
            infoMissionRef.current?._id === change.idMission
          ) {
            const combinedArray = Object.values(
              searchTempWorkerResultRef.current
            ).flat();

            const userExists = combinedArray.some(
              (item) => item.id_user === change.idUser
            );

            if (userExists) {
              const url = 'admin/searchTempWorker';
              const data = { token, idMission: change.idMission };
              ApiDatabase.updateCacheEntry(url, data, change.searchTempWorker);

              const categories = [
                'complete',
                'partial',
                'not_updated',
                'incomplete',
              ];

              const searchTempWorkerResultUpdated = {
                ...searchTempWorkerResultRef.current,
              };

              categories.forEach((category) => {
                searchTempWorkerResultUpdated[category] =
                  searchTempWorkerResultUpdated[category].filter(
                    (user) => user.id_user !== change.idUser
                  );
              });

              categories.forEach((category) => {
                const incomingUsers = change.searchTempWorker[category] || [];
                const userData = incomingUsers.find(
                  (u) => u.id_user === change.idUser
                );
                if (userData) {
                  searchTempWorkerResultUpdated[category] = [
                    ...searchTempWorkerResultUpdated[category],
                    { ...userData },
                  ];
                }
              });

              setSearchTempWorkerResult(searchTempWorkerResultUpdated);
            }
          }
        };

        socketRef.current.on('searchMissionUpdated', (change) =>
          handleSearchMissionUpdated(change, 'updated')
        );
        socketRef.current.on('searchMissionInserted', (change) =>
          handleSearchMissionInserted(change, 'updated')
        );
        socketRef.current.on('tempWorkerPostulateMissionUpdated', (change) =>
          handleTempWorkerPostulateMissionUpdated(change, 'updated')
        );
        socketRef.current.on('tempWorkerInviteMissionUpdated', (change) =>
          handleTempWorkerInviteMissionUpdated(change, 'updated')
        );
        socketRef.current.on('missionDetailsByIdUpdated', (change) =>
          handleMissionDetailsByIdUpdated(change, 'updated')
        );
        socketRef.current.on('searchTempWorkerMissionUpdated', (change) =>
          handleSearchTempWorkerMissionUpdated(change, 'updated')
        );
      }
    };

    manageSocket();
  }, []);

  const removeAllParameters = () => {
    const params = new URLSearchParams(window.location.search);

    const urlWithoutParams = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, urlWithoutParams);
  };

  return (
    <roadContext.Provider
      value={{
        language,
        modalShow,
        setModalShow,
        modalType,
        setModalType,
        modalData,
        setModalData,
        modalDataReturn,
        setModalDataReturn,
        slideOverShow,
        setSlideOverShow,
        slideOverType,
        setSlideOverType,
        typeNav,
        email,
        setEmail,
        name,
        setName,
        updateUser,
        auth,
        setAuth,
        refreshUser,
        setRefreshUser,
        refreshLoadUser,
        refreshWorker,
        setRefreshWorker,
        refreshLoadWorker,
        sidebarOpen,
        setSidebarOpen,
        sidebarExpanded,
        setSidebarExpanded,
        expID,
        setExpID,
        objID,
        setObjID,
        objType,
        setObjType,
        userUnAssigned,
        setUserUnAssigned,
        adminLink,
        setAdminLink,
        adminTempWorkerId,
        setAdminTempWorkerId,
        adminType,
        setAdminType,
        updateTempWorker,
        setUpdateTempWorker,
        timeData,
        setTimeData,
        refreshTime,
        setRefreshTime,
        timeCheck,
        setTimeCheck,
        content,
        setContent,
        totalH,
        setTotalH,
        coeffs,
        setCoeffs,
        infoVivier,
        setInfoVivier,
        listVivier,
        setListVivier,
        refreshVivierLog,
        setRefreshVivierLog,

        currentPageTimeSheetToBeChecked,
        setCurrentPageTimeSheetToBeChecked,
        timeSheetSearchTimeSheetToBeChecked,
        setTimeSheetSearchTimeSheetToBeChecked,
        timeSheetSearchTypeTimeSheetToBeChecked,
        setTimeSheetSearchTypeTimeSheetToBeChecked,
        dateRangeTimeSheetToBeChecked,
        setDateRangeTimeSheetToBeChecked,

        currentPageTimeSheetChecked,
        setCurrentPageTimeSheetChecked,
        timeSheetSearchTimeSheetChecked,
        setTimeSheetSearchTimeSheetChecked,
        timeSheetSearchTypeTimeSheetChecked,
        setTimeSheetSearchTypeTimeSheetChecked,
        dateRangeTimeSheetChecked,
        setDateRangeTimeSheetChecked,

        currentPageTimeSheetLitigation,
        setCurrentPageTimeSheetLitigation,
        timeSheetSearchTimeSheetLitigation,
        setTimeSheetSearchTimeSheetLitigation,
        timeSheetSearchTypeTimeSheetLitigation,
        setTimeSheetSearchTypeTimeSheetLitigation,
        dateRangeTimeSheetLitigation,
        setDateRangeTimeSheetLitigation,

        openModal,
        setOpenModal,

        missionDetails,
        setMissionDetails,
        scheduleWeeks,
        setScheduleWeeks,
        updSchedule,
        setUpdSchedule,
        activeScheduleWeekDay,
        setActiveScheduleWeekDay,
        scheduleWeeksNew,
        setScheduleWeeksNew,
        refreshMissionDetails,
        setRefreshMissionDetails,

        refreshCompanyBigAccount,
        setRefreshCompanyBigAccount,
        removeAllParameters,

        reviewUntreatedDelete,
        setReviewUntreatedDelete,
        refreshUntreated,
        setRefreshUntreated,

        refreshQualifications,
        setRefreshQualifications,
        refreshQualification,
        setRefreshQualification,

        usersVivier,
        setUsersVivier,

        userVivierToArchive,
        setUserVivierToArchive,
        userVivierToArchiveUpdated,
        setUserVivierToArchiveUpdated,

        commentID,
        setCommentID,
        commentIDUpd,
        setCommentIDUpd,

        userData,
        setUserData,
        tempWorkerData,
        setTempWorkerData,

        target,
        setTarget,

        assignationMissionTempWorker,
        setAssignationMissionTempWorker,

        modifyMissionDetailData,
        setModifyMissionDetailData,

        listChangesMissionDetail,
        setListChangesMissionDetail,
        isChangesNeedsNewContractMissionDetail,
        setIsChangesNeedsNewContractMissionDetail,

        searchMissionResult,
        setSearchMissionResult,

        searchTempWorkerAssignationResult,
        setSearchTempWorkerAssignationResult,
        searchTempWorkerAssignationRefusedResult,
        setSearchTempWorkerAssignationRefusedResult,

        searchTempWorkerInviteResult,
        setSearchTempWorkerInviteResult,

        infoMission,
        setInfoMission,

        searchTempWorkerResult,
        setSearchTempWorkerResult,
      }}
    >
      {children}
    </roadContext.Provider>
  );
}

export { ContextProvider, roadContext };
