import { useEffect, useState } from 'react';

// import components
import { Loading2, SearchLoop } from 'assets/icons/index.js';
import axios from 'axios';
import Button from 'components/button';
import ModalBody from 'components/modal/components/body';
import ModalFooter from 'components/modal/components/footer';
import Modalheader from 'components/modal/components/header';
import useModal from 'components/modal/useModal';
import Fuse from 'fuse.js';
import UseContext from 'hooks/useContext.js';
import useWindowDimensions from 'hooks/useWindowDimensions.js';
import { toast } from 'react-toastify';
import removeAccents from 'remove-accents';

const ModalAddVivier = () => {
  const { toggle } = useModal();
  const { infoVivier, setUsersVivier } = UseContext();
  const [search, setSearch] = useState('');
  const [vivierAll, setVivierAll] = useState([]);
  const [vivierFiltered, setVivierFiltered] = useState([]);
  const [isWaiting, setIsWaiting] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [listAddVivier, setAddVivier] = useState([]);
  const [selectMQ, setSelectMQ] = useState(false);
  const [selectGP, setSelectGP] = useState(false);
  const [column, setColumn] = useState('test');
  const { height, width } = useWindowDimensions();

  const kanbanColumns = [
    { name: 'wish', title: 'Souhait', icon: '🧞' },
    { name: 'test', title: 'Test', icon: '⏳' },
    { name: 'valid', title: 'Validé', icon: '✅' },
    { name: 'refused', title: 'Refusé', icon: '❌' },
    { name: 'archive', title: 'Archivé', icon: '📦' },
  ];

  const handleCheckboxChange = (id) => {
    if (listAddVivier.includes(id)) {
      setAddVivier(listAddVivier.filter((item) => item !== id));
    } else {
      setAddVivier([...listAddVivier, id]);
    }
  };

  const isAllUserVivier = (users, vivierUsers) => {
    return users.every((userID) =>
      vivierUsers.some((vivierUser) => vivierUser.id_user === userID)
    );
  };

  // Chargement initial des viviers une seule fois
  const fetchInitialVivier = async () => {
    setIsWaiting(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}vivier/vivierAll`
      );
      // On stocke tous les viviers et on arrête le spinner
      setVivierAll(data);
      setVivierFiltered(data);
      setIsWaiting(false);
    } catch (error) {
      if (error.name !== 'CanceledError') {
        console.log(error);
      }
      setIsWaiting(false);
    }
  };

  useEffect(() => {
    fetchInitialVivier();
  }, []);

  // Utilisation de Fuse.js pour la recherche locale
  useEffect(() => {
    if (!vivierAll || vivierAll.length === 0) return;

    if (search.trim() === '') {
      // Si aucun terme de recherche, on reprend la liste complète
      setVivierFiltered(vivierAll);
    } else {
      const options = {
        keys: ['name'],
        includeScore: true,
        threshold: 0.3,
        distance: 100,
        // On applique removeAccents pour uniformiser la recherche
        getFn: (obj, path) => {
          const value = Fuse.config.getFn(obj, path);
          if (value == null) {
            return '';
          }
          return removeAccents(value.toString().toLowerCase());
        },
      };

      const fuse = new Fuse(vivierAll, options);
      const result = fuse.search(removeAccents(search.toLowerCase()));
      setVivierFiltered(result.map((r) => r.item));
    }
  }, [search, vivierAll]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (listAddVivier.length > 0 && column !== '') {
      setIsLoading(true);
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}vivier/addVivier`,
        data: {
          listUsers: infoVivier.listUsers,
          listViviers: listAddVivier,
          vivierStatus: column,
        },
      })
        .then((res) => {
          setUsersVivier(res.data);
          toast.success(
            infoVivier.listUsers.length > 1
              ? 'Les utilisateurs ont été ajoutés au vivier'
              : "L'utilisateur a été ajouté au vivier"
          );
          toggle();
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.name !== 'CanceledError') {
            console.log(err);
            setIsLoading(false);
          }
        });
    } else {
      toast.error(
        'Vous devez sélectionner au moins 1 candidat pour faire un ajout à un vivier'
      );
    }
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Ajouter à un vivier
      </Modalheader>
      <ModalBody>
        <div className="flex flex-col w-full gap-8">
          <div className="flex items-center gap-x-4">
            <div className="relative text-gray-500 ">
              <input
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className="w-56 h-10 pl-10 pr-3 ft-b placeholder-gray-500 border border-gray-300 rounded-md "
                type="text"
                placeholder="Nom du vivier"
                required
              />
              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <SearchLoop wh={18} color={'#666666'} />
              </div>
            </div>
          </div>
          {isWaiting ? (
            <div
              className={`flex flex-col ${
                width <= 460 ? 'gap-5' : 'gap-10'
              } justify-center items-center h-80`}
            >
              <div className={'animate-spin'}>
                <Loading2 wh={width <= 460 ? 40 : 60} color={'#FF9600'} />
              </div>
            </div>
          ) : (
            <>
              <div className="h-72 overflow-y-auto">
                {vivierFiltered.map((item) => {
                  if (item._id.toString() === '64e8cfd10b4f73f442432c9f')
                    return null;
                  else
                    return (
                      <div className="py-2" key={item._id}>
                        <label className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            checked={
                              isAllUserVivier(
                                infoVivier.listUsers,
                                item?.user_preferences
                              )
                                ? true
                                : listAddVivier.includes(item._id)
                            }
                            onChange={() => handleCheckboxChange(item._id)}
                            disabled={isAllUserVivier(
                              infoVivier.listUsers,
                              item?.user_preferences
                            )}
                          />
                          <div className="flex flex-col pl-4">
                            <span>{item.name} </span>
                            <span className="ft-sm text-gray-400">
                              {item?.user_preferences.length} candidats
                            </span>
                          </div>
                        </label>
                      </div>
                    );
                })}
              </div>
              <div className="">
                <label className="ft-sm text-gray-500">
                  <div>Sélectionner la colonne</div>
                  <div>
                    <select
                      className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900"
                      value={column}
                      onChange={(e) => setColumn(e.target.value)}
                    >
                      {kanbanColumns?.map((kanbanColumn, index) => (
                        <option key={index} value={kanbanColumn.name}>
                          {kanbanColumn.icon} - {kanbanColumn.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </label>
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'SECONDARY'}>
              Fermer
            </Button>
            <Button size={'LG'} color={'DISABLED'}>
              Confirmation ...
            </Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
              Fermer
            </Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>
              Confirmer
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalAddVivier;
