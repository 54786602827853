import { useEffect, useState } from 'react';
import TbodyVivier from './components/tbody.vivier';

const TableVivier = ({ users, setSortName, setAddVivier, sortName, list }) => {
  const [selectAll, setSelectAll] = useState(false);

  // Vérifiez si toutes les cases à cocher individuelles sont cochées, puis mettez à jour l'état de la case à cocher d'en-tête
  useEffect(() => {
    if (users && Array.isArray(users)) {
      if (list > 0) {
        const allChecked = users.every((user) => list.includes(user._id));
        setSelectAll(allChecked);
      }
    }
  }, [users, list]);

  const handleSortName = () => {
    setSortName((prevState) => !prevState);
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    // Mise à jour de la liste des ID en fonction de l'état de la case à cocher d'en-tête
    if (!selectAll) {
      // Si la case à cocher d'en-tête est cochée, ajouter tous les ID au tableau
      setAddVivier(users.map((user) => user._id));
    } else {
      // Sinon, supprimer tous les ID du tableau
      setAddVivier([]);
    }
  };

  return (
    <table className="table-fixed w-full divide-y divide-gray-200 bg-white overflow-y-auto">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 px-4 font-normal text-left rtl:text-right text-sm text-gray-700 sticky left-0 w-64 bg-white z-10"
          >
            <div className="flex items-center gap-x-3">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={() => toggleSelectAll()}
              />
              <span>CANDIDAT</span>
            </div>
          </th>
          <th
            scope="col"
            className="relative py-3.5 px-4 w-80 text-left text-sm text-gray-700 bg-white"
          >
            CONTACT
          </th>
          {/* <th scope="col" className="relative py-3.5 px-4 w-60 text-left text-sm text-gray-700 bg-white">
                           SECTEUR
                        </th> */}
          <th
            scope="col"
            className="relative py-3.5 px-4 w-96 text-left text-sm text-gray-700 bg-white"
          >
            VIVIER
          </th>
          <th
            scope="col"
            className="relative py-3.5 px-4 w-24 text-left text-sm text-gray-700 bg-white"
          ></th>
          <th
            scope="col"
            className="py-3.5 px-4 w-16 text-left text-sm text-gray-700 bg-white sticky right-0 z-10"
          >
            <span className="sr-only">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {users?.length > 0 &&
          users.map((user, key) => (
            <TbodyVivier
              key={key}
              champsBody={user}
              setAddVivier={setAddVivier}
              list={list}
              selectAll={selectAll}
            />
          ))}
      </tbody>
    </table>
  );
};

export default TableVivier;
