import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useContext from '../../../../../hooks/useContext';
import ApiDatabase from '../../../../../server';
import Button from '../../../../button';
import ModalBody from '../../../components/body';
import ModalFooter from '../../../components/footer';
import Modalheader from '../../../components/header';
import useModal from '../../../useModal';

const AdminModalMissionAssignationConfirmation = () => {
  const { toggle } = useModal();
  const { assignationMissionTempWorker, setModalDataReturn } = useContext();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('xsrfToken');

  useEffect(() => {
    if (assignationMissionTempWorker.idTempWorker) {
      ApiDatabase.getUserById(
        { token, id_user: assignationMissionTempWorker.idTempWorker },
        (data) => {
          setUser(data);
        },
        (err) => {
          if (err.name !== 'CanceledError') {
            console.log(err);
          }
        }
      );
    }
  }, [assignationMissionTempWorker]);

  const handleSubmit = () => {
    if (
      assignationMissionTempWorker.idMission &&
      assignationMissionTempWorker.idTempWorker
    ) {
      setLoading(true);
      ApiDatabase.assignTempWorkerToMission(
        {
          token,
          idMission: assignationMissionTempWorker.idMission,
          idTempWorker: assignationMissionTempWorker.idTempWorker,
        },
        async (data) => {
          toast.success("L'intérimaire a bien été assigné à la mission");
          setModalDataReturn('mission assigned');
          toggle();
        },
        async (err) => {
          if (err.name !== 'CanceledError') {
            toast.error(
              err.response.data.message ||
                err.response.data.error ||
                "Un problème est survenue lors de l'assignation de la mission"
            );
            setModalDataReturn('mission assigned');
            toggle();
          }
        }
      );
    }
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Assigner la mission
      </Modalheader>
      <ModalBody>
        Êtes-vous sûr d’attribuer la mission à {user.firstname} {user.lastname}{' '}
        ? L’intérimaire et l’entreprise seront notifiés.
      </ModalBody>
      <ModalFooter>
        {loading ? (
          <>
            <Button size={'LG'} color={'DISABLED'}>
              Annuler
            </Button>
            <Button size={'LG'} color={'DISABLED'}>
              Assignation de la mission...
            </Button>
          </>
        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
              Annuler
            </Button>
            <Button size={'LG'} onClick={() => handleSubmit()}>
              Confirmer
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default AdminModalMissionAssignationConfirmation;
